import { useEffect, useState } from 'react';
import PageHeader from '../components/common/PageHeader';
import ApiService from '../api/ApiService';
import GoToTop from '../helpers/GoToTop';
import Fancybox from '../helpers/Fancybox';
import Skeleton from 'react-loading-skeleton';
import MetaData from '../helpers/MetaData';

const TempleVideoList = () => {
    const [videos, setVideos] = useState([]);
    const [showSkeleton, setShowSkeleton] = useState(true);
    console.log("videosvideosvideos", videos);

    const fetchTempleVideos = async () => {
        try {
            const res = await ApiService.fetchTempleVideos();
            setVideos(res);
            setShowSkeleton(false);
        } catch (error) {
            console.error('Error fetching temple videos:', error);
        }
    };

    useEffect(() => {
        fetchTempleVideos();
    }, []);

    return (
        <>
            <MetaData title="Temple Videos" />
            <PageHeader title="TEMPLE VIDEOS" imgSrc="/images/page-header/20.webp" />


            <section className="yt-links-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <div className="heading">
                                <h2 className="head-1">TEMPLE VIDEOS </h2>
                                <p className='b-line'>
                                    Explore our collection of temple videos.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="video-wrap">

                        <div className="row my-5">
                            {
                                videos?.length === 0 ? <>
                                    <div className="col-lg-4 col-md-6 mb-5">
                                        <Skeleton height={230} />
                                    </div>
                                    <div className="col-lg-4 col-md-6 mb-5">
                                        <Skeleton height={230} />
                                    </div>
                                    <div className="col-lg-4 col-md-6 mb-5">
                                        <Skeleton height={230} />
                                    </div>
                                </> : <>
                                    {
                                        videos?.map((video, i) => (
                                            <div key={i} className="col-md-4 " >
                                                <div className="video-holder">
                                                    <Fancybox>
                                                        <a data-fancybox="video" href={video?.video} data-thumb={`${process.env.REACT_APP_S3_URL}/uploads/media/${video?.thumbnailImage}`}  >

                                                            <figure className="mb-0 thumbnil">
                                                                <img src={`${process.env.REACT_APP_S3_URL}/uploads/media/${video?.thumbnailImage}`} alt="" />
                                                            </figure>
                                                            <div className="yt-logo">
                                                                <figure className="yt-figure">
                                                                    <img src="/images/homepage/why-krishna/youtube.jpg" alt="" />
                                                                </figure>
                                                            </div>
                                                            <div className="reason">
                                                                <p>{video?.title} </p>
                                                            </div>
                                                        </a>
                                                    </Fancybox>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </>
                            }
                        </div>
                    </div>

                </div>

            </section>















            <GoToTop />
        </>
    );
};

export default TempleVideoList;
