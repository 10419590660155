import React, { useEffect, useState } from "react";
import GoToTop from "../helpers/GoToTop";
import { VscClose } from "react-icons/vsc";
import { Link, useLocation } from "react-router-dom";
import ApiService from "../api/ApiService";

const PaymentFailed = () => {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const type = new URLSearchParams(search).get("type");
  const smsCampaign = new URLSearchParams(search).get('sms-campaign');
  const [campaignDetails, setCampaignDetails] = useState(null);

  const fetchFailedCampaignDetails = async () => {
    try {
      const res = await ApiService.fetchFailedCampaignDetails(id);
      // console.log(res);
      setCampaignDetails(res);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (type === "campaign") {
      fetchFailedCampaignDetails();
    }
  }, []);

  return (
    <>
      <section className="thank-you">
        <div className="container">
          <div className="col-lg-6 mx-auto">
            <div className="thank-you-wrapper">
              <div className="heading">
                <span className="icon failed">
                  <VscClose />
                </span>
                <h2 className="head-1 text-danger">Payment Unsuccessful</h2>
                <p>Your payment was unsuccessful. Please try again.</p>
              </div>
              <div className="d-flex justify-content-center">
                {type === "lifeMembership" && (
                  <Link
                    to={`/life-membership?lifeMembershipId=${id}`}
                    className="custom-btn-cls box-hover"
                  >
                    Retry Payment
                  </Link>
                )}
                {type === "campaign" && (
                  <Link
                    to={`/campaign-donation/${campaignDetails?.donationType?.slug}/${campaignDetails?.amount}?donationId=${id}${smsCampaign ? `&&sms-campaign=${smsCampaign}` : ''}`}
                    className="custom-btn-cls box-hover"
                  >
                    Retry Payment
                  </Link>
                )}
                {type === "donation" && (
                  <a
                    href={`/donation-form?donationId=${id}`}
                    className="custom-btn-cls box-hover"
                  >
                    Retry Payment
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <GoToTop />
    </>
  );
};

export default PaymentFailed;
