import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ApiService from '../api/ApiService.js'
import PageHeader from '../components/common/PageHeader.jsx'
import DonationCampaigns from '../components/donate/DonationCampaigns.jsx'
import GoToTop from '../helpers/GoToTop.js'
import MetaData from '../helpers/MetaData.js'

const Donate = () => {

  const scrollToElement = () => {
    const element = document.getElementById('scrollToAllPages');
    // console.log("elements",element);
    if(element) {
      element.scrollIntoView({behavior : 'smooth'})
    }
  };
  const [top, setTop] = useState(0)

  const [meta, setMeta] = useState(null)
    const fetchMeta = async (pagename) => {
        try {
            let res = await ApiService.fetchMetaData(pagename)
            setMeta(res)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchMeta('donate')
    }, [])
    useEffect(()=>{

      scrollToElement();
      },[])

  return (
    <>
        <MetaData title="Donate - ISKCON of Attapur" />
        <PageHeader title="Donate" imgSrc="/images/page-header/23.webp" />
        <DonationCampaigns setTop={setTop} id="scrollToAllPages" />
        <GoToTop top={top} />
    </>
  )
}

export default Donate