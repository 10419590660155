import PageHeader from "../components/common/PageHeader";
import { useEffect, useRef, useState } from "react";
import GoToTop from "../helpers/GoToTop";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import ApiService from "../api/ApiService";
import { useForm, Controller } from "react-hook-form";
import notification from "../helpers/notification";
import MetaData from "../helpers/MetaData";
import { BsCheckLg } from "react-icons/bs";
import Fancybox from "../helpers/Fancybox";
import Skeleton from "react-loading-skeleton";
import { BsFillPlayCircleFill } from "react-icons/bs";
import ReCAPTCHA from "react-google-recaptcha";
import { useContext } from "react";
import AppContext from "../context/AppContext";
import { useCallback } from "react";
import axios from "axios";
import moment from "moment/moment";
import Select from "react-select";
import Tooltip from "@mui/material/Tooltip";
import { AiOutlineCalendar } from "react-icons/ai";
import { schemeName } from "../constants/data/nityaSevaData";

const expiry = process.env.REACT_APP_OTPEXPIRY;
let timer = null;

const NityaSevaSubForm = () => {

  const nityaAmount = localStorage.getItem("nitya-seva-amt");



  //console.log(nityaAmount);


  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
    getValues,
    control,
  } = useForm({
    defaultValues: {
      state: "",
      city: "",
    },
  });


  const scrollToElement = () => {
    const element = document.getElementById("scrollToDonateFrom");
    // console.log("elements",element);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  // const formRef = useRef();
  const navigate = useNavigate();
  const { slug } = useParams();
  const { setToken, user, token } = useContext(AppContext);

  if (nityaAmount === '') {
    navigate('/nitya_seva_subscribe')
  }

  // if(!user){
  //   navigate('/login?sub=1')
  // } 



  const search = useLocation().search;
  const donationId = new URLSearchParams(search).get("donationId");

  const [donationTypes, setDonationTypes] = useState([null]);
  const [popularDonation, setPopularDonations] = useState([]);
  const [donationType, setDonationType] = useState(null);
  const [donationImg, setDonationImg] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [categories, setCategories] = useState([]);
  const [type, setType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isRetry, setIsRetry] = useState(false);
  const [retryDonationDetails, setRetryDonationDetails] = useState(null);
  const [showMemoryBox, setShowMemoryBox] = useState(false);
  const [goseva, setGoseva] = useState(0);
  const [bhagavadGitaPrice, setBhagavadGitaPrice] = useState(0);
  const [isGoseva, setIsGoseva] = useState(false);
  const [isSadhuBhojanSeva, setIsSadhuBhojanSeva] = useState(false);
  const [isBhagvadGita, setIsBhagvadGita] = useState(false);
  const [top, setTop] = useState(0);
  const [showOtp, setShowOtp] = useState(false);
  const [isVerified, setIsverified] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpExp, setOtpExp] = useState(expiry);
  const [showresendlink, setShowResendLink] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [icsdontype, setIcsDontype] = useState("A General Donation");
  const [ics_data, setICSdata] = useState();
  const [ics_address, setIcsAddress] = useState([]);
  const [ics_pincode, setIcsPincode] = useState([]);
  const [icsdatawl, setICSdatawl] = useState([]);
  const [item_data, setItemData] = useState([]);
  const [wl_loginics, setWithlogin] = useState([]);
  const [wl_icsname, setIcsname] = useState("");
  const [icsid, setICSid] = useState(localStorage.getItem("ics_id"));



  const [recieptId, setRecieptId] = useState("");

  const currentDate = new Date();
  const formattedDate = currentDate.toDateString();




  const fetchStateCity = async (pin) => {
    try {
      let url = `https://api.postalpincode.in/pincode/${pin}`;

      let config = {
        method: "GET",
        url,
      };
      const { data } = await axios(config);

      let cityVal = "";
      if (data[0]?.PostOffice?.length > 1) {
        data[0]?.PostOffice.map((ct) => {
          if (ct.Block !== "NA") {
            return (cityVal = ct.Block);
          }
        });
      } else {
        cityVal = data[0]?.PostOffice[0]?.Block;
      }

      if (data[0]?.Status === "Success") {
        setValue(
          "city",
          cityVal !== "" ? cityVal : data[0]?.PostOffice[0]?.District
        );
        setValue("state", data[0]?.PostOffice[0]?.State);
      } else {
        notification("warning", "Enter valid PIN code!");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const formRef = useCallback((node) => {
    if (node) {
      setTop(node.offsetTop);
    }
  });

  useEffect(() => {
    reset({
      schemeName: "",
      amount: "2020",
      interval: "3",
      startDate: "",
      endDate: "",
    });
  }, []);
  useEffect(() => {
    scrollToElement();
  }, []);

  useEffect(() => {
    const showBox = watch((value) => {
      const { amount, sadhuBhojanSeva, bhagavadGita, goseva } = value;
      // console.log(value)
      let total =
        (amount ? Number(amount) : 0) +
        (sadhuBhojanSeva ? Number(sadhuBhojanSeva) : 0) +
        (bhagavadGita ? Number(bhagavadGita) * 200 : 0) +
        (goseva ? Number(goseva) * 120 : 0);
      setTotalAmount(total);

      setShowMemoryBox(value.memoryOfSomeone);
    });
    return () => showBox.unsubscribe();
  }, [watch]);

  const recaptchaRef = useRef();

  const fetchDonationType = async () => {
    try {
      const res = await ApiService.fetchDonationService(slug);
      //console.log(res.donationType);
      setDonationType(res.donationType);
      setIsGoseva(res.donationType.goseva);
      setIsSadhuBhojanSeva(res.donationType.sadhuBhojanSeva);
      setIsBhagvadGita(res.donationType.bhagvadGita);
      if (res.donationType.popular) {
        setPopularDonations(res.donationTypes);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  };
  const fetchPopularDonations = async () => {
    try {
      const res = await ApiService.fetchPopularDonations();
      // console.log(res)
      setPopularDonations(res);
      if (!slug) {
        setDonationImg(res[0].image);
        setVideoUrl(res[0].videoUrl);
        setIsGoseva(res[0].goseva);
        setIsSadhuBhojanSeva(res[0].sadhuBhojanSeva);
        setIsBhagvadGita(res[0].bhagvadGita);

        let details = {};
        if (!isRetry) {
          details.donationType = res[0]?._id;
        }

        if (user && !isRetry) {
          details.name = user.name;
          details.phone = user.phone;
          details.email = user.email;
          details.pan = user.pan;
          details.address =
            user.streetName && user.areaName
              ? `${user.streetName}, ${user.areaName}`
              : user.address;
          details.pin = user.pin;
          details.state = user?.state;
          details.city = user?.city;
        }
        reset(details);
        if (user?.pin) {
          fetchStateCity(user?.pin);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
    if (!slug) {
      fetchPopularDonations();
    } else {
      // formRef.current.scrollIntoView({ behavior: "smooth" });
      fetchDonationType();
    }
    if (user) {
      setIsverified(true);
    }
  }, [isRetry, donationId, user]);

  useEffect(() => {
    if (donationType?.popular) {
      let details = { donationType: donationType?._id };
      if (user && !isRetry) {
        details.name = user.name;
        details.phone = user.phone.trim();
        details.email = user.email;
        details.pan = user.pan;
        details.address =
          user.streetName && user.areaName
            ? `${user.streetName}, ${user.areaName}`
            : user.address;
        details.pin = user.pin;
        details.state = user?.state;
        details.city = user?.city;
      }
      reset(details);
      if (user?.pin) {
        fetchStateCity(user?.pin);
      }
    } else {
      let details = {
        donationType: donationType?.title
          ? donationType?.title.split("|")[0] +
          " " +
          (donationType?.title?.split("|").length !== 1
            ? donationType?.title?.split("|")[1]
            : "")
          : "",
        amount: donationType?.amount,
      };

      if (user && !isRetry) {
        details.name = user.name;
        details.phone = user.phone.trim();
        details.email = user.email;
        details.pan = user.pan;
        details.address =
          user.streetName && user.areaName
            ? `${user.streetName}, ${user.areaName}`
            : user.address;
        details.pin = user.pin;
        details.state = user?.state;
        details.city = user?.city;
      }
      reset(details);
      if (user?.pin) {
        fetchStateCity(user?.pin);
      }
    }
    setDonationImg(donationType?.image);
    setVideoUrl(donationType?.videoUrl);
  }, [donationType, user, isRetry]);

  function getTime(time) {
    return (
      Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2)
    );
  }

  // const handleSendOtp = async (phone) => {
  //   if (timer) {
  //     clearInterval(timer);
  //   }
  //   if (phone.length >= 10) {
  //     try {
  //       const res = await ApiService.sendOtp({ number: phone });
  //       notification("success", res.message);
  //       setShowTimer(true);
  //       timer = setInterval(() => {
  //         setOtpExp((prev) => prev - 1);
  //       }, 1000);
  //     } catch (err) {
  //       console.log(err);
  //       notification("error", err.response.data.message);
  //     }
  //   }
  // };

  //console.log(item_data)
  //console.log(icsdatawl)

  // const handleVerifyOtp = async (otp) => {
  //   try {
  //     let otpData = {
  //       number: getValues("phone"),
  //       otp,
  //     };
  //     const res = await ApiService.loginUser(otpData);
  //     // console.log(res);
  //     localStorage.setItem(`token`, res.token);
  //     setToken(res.token);
  //     notification("success", res.message);
  //     setOtp("");
  //     setIsverified(true);
  //     setShowOtp(false);
  //     madeDonation(getValues());
  //   } catch (err) {
  //     console.log(err);
  //     notification("error", err.response.data.message);
  //   }
  // };


  // console.log(ics_idd)

  // SEND DATA TO ICS


  //console.log(icsid);
  // const madeDonation = async (data) => {
  //   console.log("madeDonation", data);
  //   try {
  //     const token = await recaptchaRef.current.executeAsync();
  //     //const ics_id = localStorage.getItem("ics_id");
  //     data.token = token;

  //     data.name = wl_icsname ? wl_icsname : data.name;

  //     data.icsid = icsid ? icsid : '';

  //     data.userType = user ? "user" : "guest";

  //     let _doonationType;
  //     if (isRetry) {
  //       _doonationType = retryDonationDetails.donationType;
  //     } else {
  //       _doonationType =
  //         popularDonation.length > 0 || donationType.popular
  //           ? data.donationType
  //           : donationType?._id;
  //     }

  //     const res = await ApiService.subscribeNityaSeva({
  //       ...data,
  //       type,
  //       donationType: _doonationType,
  //     });


  //     const options = {
  //       key: process.env.REACT_APP_RAZORPAY_KEY,
  //       currency: res.currency,
  //       amount: res.amount,
  //       name: process.env.REACT_APP_PROJECT_NAME,
  //       description: "Help Us to Help the Society",
  //       image: `${process.env.REACT_APP_S3_URL}/assets/img/logo.jpg`,
  //       order_id: res.id,
  //       handler: function (response) {
  //         const textForStorage = response.razorpay_payment_id
  //         localStorage.setItem('my-key', textForStorage);
  //         const textFromStorage = localStorage.getItem('my-key');

  //         if (textFromStorage) {
  //           // sendDonationICS(data, res.donationId, final_amt);
  //           navigate(`/thank-you/${res.donationId}/donation`, { replace: true });

  //         }
  //       },
  //       prefill: {
  //         name: res.name ? res.name : "",
  //         email: res.email ? res.email : "",
  //         contact: res.phone ? res.phone : "",
  //       },
  //       notes: {
  //         type: "nityasevadonation",
  //       },
  //       theme: {
  //         color: "#FFD370",
  //       },
  //       modal: {
  //         ondismiss: async function () {
  //           const cancelDonation = await ApiService.cancelDonation(res.id);
  //         },
  //       },
  //     };

  //     const paymentObject = new window.Razorpay(options);
  //     paymentObject.open();

  //     paymentObject.on("payment.failed", function (response) {
  //       // console.log(response)
  //       navigate(`/payment-failed?id=${res.donationId}&&type=donation`, {
  //         replace: true,
  //       });
  //     });
  //     setLoading(false);
  //     // recaptchaRef.current.reset();
  //   } catch (err) {
  //     setLoading(false);
  //     console.log(err);
  //     notification("error", "Unable to make donation, try again later!");
  //   }
  // };

  const madeDonationNityaseva = async (data) => {
    try {
      const token = await recaptchaRef.current.executeAsync();
      //const ics_id = localStorage.getItem("ics_id");
      data.token = token;

      data.name = wl_icsname ? wl_icsname : data.name;

      data.icsid = icsid ? icsid : '';

      data.userType = user ? "user" : "guest";

      let _doonationType;
      if (isRetry) {
        _doonationType = retryDonationDetails.donationType;
      } else {
        _doonationType =
          popularDonation.length > 0 || donationType.popular
            ? data.donationType
            : donationType?._id;
      }

      const res = await ApiService.subscribeNityaSevaAutopay({
        ...data,
        type,
        donationType: _doonationType,
      });

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,

        name: process.env.REACT_APP_PROJECT_NAME,
        amount: res.nityaAmount,
        subscription_id: res.subscriptionId,
        description: "Help Us to Help the Society",
        image: `${process.env.REACT_APP_S3_URL}/assets/img/logo.jpg`,
        handler: function (response) {
          // console.log("responce", response);
          const textForStorage = response.razorpay_payment_id;
          localStorage.setItem("my-key", textForStorage);
          const textFromStorage = localStorage.getItem("my-key");

          if (textFromStorage) {
            // sendDonationICS(data, res.donationId, final_amt);
            navigate(`/thank-you/${res.nityaSevaId}/subscription`, {
              replace: true,
            });
          }
        },
        prefill: {
          name: res.name ? res.name : "",
          email: res.email ? res.email : "",
          contact: res.phone ? res.phone : "",
        },
        notes: {
          type: "donation",
        },
        theme: {
          color: "#FFD370",
        },
        modal: {
          ondismiss: async function () {
            const cancelDonation = await ApiService.cancelDonation(res.id);
          },
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();

      paymentObject.on("payment.failed", function (response) {
        // console.log(response)
        navigate(`/payment-failed?id=${res.donationId}&&type=donation`, {
          replace: true,
        });
      });
      setLoading(false);
      // recaptchaRef.current.reset();
    } catch (err) {
      setLoading(false);
      console.log(err);
      notification("error", "Unable to make donation, try again later!");
    }
  };



  const handleNityaSevaSub = async (data) => {
    //console.log("nityaseva", data);
    setLoading(true);

    madeDonationNityaseva(data)
    // madeDonation(data);
  };

  const resendOtp = () => {
    setOtpExp(expiry);
    // handleSendOtp(getValues("phone"));
    setShowResendLink(false);
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };


  const schemeData = [
    {
      title: "Your Nitya Seva",
      subtitle: "₹ 100",
      amount: "100",
      image: "food-for-life-1.webp",
    },
    {
      title: "Your Nitya Seva",
      subtitle: "₹ 500",
      amount: "500",
      image: "food-for-life-2.webp",
    },
    {
      title: "Your Nitya Seva",
      subtitle: "₹ 1,000",
      amount: "1000",
      image: "food-for-life-3.webp",
    },
    {
      title: "Your Nitya Seva",
      subtitle: "₹ 2,500",
      amount: "2500",
      image: "food-for-life-3.webp",
    },
    {
      title: "Your Nitya Seva",
      subtitle: "₹ 5,000",
      amount: "5000",
      image: "food-for-life-4.webp",
    },
    {
      title: "Your Nitya Seva",
      subtitle: "₹ 7,500",
      amount: "7500",
      image: "food-for-life-4.webp",
    },
    {
      title: "Your Nitya Seva",
      subtitle: "₹ 10,000",
      amount: "10000",
      image: "food-for-life-5.webp",
    },
    {
      title: "Your Nitya Seva",
      subtitle: "₹ 15,000",
      amount: "15000",
      image: "food-for-life-6.webp",
    },
    {
      title: "Your Nitya Seva",
      subtitle: "₹ 20,000",
      amount: "20000",
      image: "food-for-life-6.webp",
    },

    {
      title: "Your Nitya Seva",
      subtitle: "₹ 25,000",
      amount: "25000",
      image: "food-for-life-6.webp",
    },
    {
      title: "Your Nitya Seva",
      subtitle: "₹ 50,000",
      amount: "50000",
      image: "food-for-life-6.webp",
    },
  ];


  // const handleDonationTypechange = (e) => {
  //   const indx = e.nativeEvent.target.selectedIndex;
  //   setIcsDontype(e.nativeEvent.target[indx].text);
  //   let _donationType = popularDonation.find(
  //     (type) => type._id === e.target.value
  //   );
  //   setDonationImg(_donationType.image);
  //   setVideoUrl(_donationType.videoUrl);
  //   setIsGoseva(_donationType.goseva);
  //   setIsSadhuBhojanSeva(_donationType.sadhuBhojanSeva);
  //   setIsBhagvadGita(_donationType.bhagvadGita);
  //   if (!_donationType.bhagvadGita) {
  //     // setValue("bhagavadGita", "");
  //     setBhagavadGitaPrice(0);
  //   }
  //   if (!_donationType.sadhuBhojanSeva) {
  //     setValue("sadhuBhojanSeva", "");
  //   }
  //   if (!_donationType.goseva) {
  //     setGoseva(0);
  //   }
  // };

  const [meta, setMeta] = useState(null);
  const fetchMeta = async (pagename) => {
    try {
      let res = await ApiService.fetchMetaData(pagename);
      setMeta(res);
    } catch (error) {
      console.log(error);
    }
  };

  const retryDonation = async () => {
    try {
      const res = await ApiService.retryDonation(donationId);
      console.log(res, "retry");

      setIsRetry(true);
      setRetryDonationDetails(res);
      setIsSadhuBhojanSeva(false);
      setIsBhagvadGita(false);
      setIsGoseva(false);
      setGoseva(0);
      setBhagavadGitaPrice(0);
      let addOns = {};
      for (let i = 0; i < res.donationAddOns.length; i++) {
        switch (res.donationAddOns[i].name) {
          case "sadhuBhojanSeva":
            addOns.sadhuBhojanSeva = res.donationAddOns[i].amount;
            setIsSadhuBhojanSeva(true);
            break;
          case "bhagavadGita":
            addOns.bhagavadGita = Number(res.donationAddOns[i].amount) / 200;
            setBhagavadGitaPrice(Number(res.donationAddOns[i].amount) / 200);
            setIsBhagvadGita(true);
            break;
          case "goseva":
            addOns.goseva = Number(res.donationAddOns[i].amount) / 120;
            setGoseva(Number(res.donationAddOns[i].amount) / 120);
            setIsGoseva(true);
            break;
        }
      }

      reset({
        ...res,
        ...addOns,
        donationType: res.type,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchMeta("donation-form");
    if (donationId) {
      retryDonation();
    }
  }, [isRetry, donationId]);

  useEffect(() => {
    if (!user) {
      if (otpExp === 0) {
        setShowTimer(false);
        setShowResendLink(true);
        clearInterval(timer);
        setOtpExp(expiry);
      }
    }
  }, [otpExp, expiry, user]);

  //GET ALL ICS PROFILE  FROM ICS THROUGH PHONE NUMBER

  const handleGetIcsData = async (aa) => {
    const res = await ApiService.getIcsData(aa);

    setICSdata(res.data);
    if (res.data[0].id > 0) {
      if (!token) {
        setLoginPopup(true);
      }
    }
  };

  // console.log(item_data);

  //GET ICS PROFILE THROUGH PARTICULAR PHONE NUMBER

  const getIcsData = async (e) => {
    const val = e.target.value.length;
    console.log(e.target.value)
    try {
      if (val === 10) {
        const res = await ApiService.getIcsData(e.target.value)
        //console.log(res)
        setICSdatawl(res.data);

      } else {
        setICSdatawl('');
        setItemData('')
        setICSdata('');
        localStorage.setItem('ics_id', '')
        setIcsAddress('')
        setIcsPincode('')
      }

    } catch (error) {
      console.log(error)
    }

  }

  //GET ICS PROFILE THROUGH FETCH BY NAME BY USING FILTER

  const fetchNameFromIcs = async (e) => {

    const res = await ApiService.getIcsData(user.phone)

    let check_len = res.data.filter((dat) => (dat.name).toUpperCase() === (e.target.value).toUpperCase())
      .map((item) => item.pan);

    console.log(check_len);

  };

  //  // MATCHING PAN NUMBER WITH ICS

  //   const fetchPanFromIcs = (e) => {
  //     const val = e.target.value.length;

  //     let pan_A = e.target.value.toUpperCase();
  //     let pan_B = e.pan.toUpperCase();

  //     if (val === 10) {

  //       if (pan_A === pan_B) {
  //         console.log("match")
  //       }
  //       else {
  //         notification('warning', "Make sure your PAN Number is correct !");
  //       }

  //     }

  //   };

  const { loginPopup, setLoginPopup } = useContext(AppContext);

  //console.log(user.number)

  const campaignRef = useCallback((node) => {
    if (node) {
      let headerHeight;
      if (window.innerWidth > 991) {
        headerHeight = 121;
      } else if (window.innerWidth > 576) {
        headerHeight = 80;
      } else {
        headerHeight = 73;
      }
      setTop(node?.offsetTop - headerHeight);
    }
  });


  const handelOther = (e) => {
    setICSdata('');
    reset({
      name: "",
      pan: "",
      address: "",
      pin: "",
      email: "",
    });
  }

  const handelOptions = (e) => {
    reset({
      name: user.name,
      phone: user.phone.trim(),
      email: user.email,
      pan: user.pan,
      address: (user.streetName && user.areaName) ? `${user.streetName}, ${user.areaName}` : user.address,
      pin: user.pin,
    });
    if (token) {
      handleGetIcsData(localStorage.getItem('ics_number'));
    }
  }


  //SELECT PROFILE IN NAME INPUT WHICH FLOW IN WEBSITE

  const handleSelectProfiles = (e) => {
    // console.log(ics_data)
    setItemData([])
    setICSid(e.target.value);
    // console.log(e.target.value)
    const responseDataa = ics_data.filter(dat => dat.id == e.target.value);
    // console.log(responseDataa)
    if (token) {
      setWithlogin(responseDataa[0].id)
      setIcsname(responseDataa[0].name)
    }

    reset({
      phone: responseDataa[0].number ? responseDataa[0].number.trim() : '',
      email: responseDataa[0].email ? responseDataa[0].email.emailAddress.trim() : '',
      pan: responseDataa[0].pan ? responseDataa[0].pan.trim() : '',
      address: responseDataa[0].address.line1 ? responseDataa[0].address.line1.trim() : '',
      pin: responseDataa[0].address.pincode ? responseDataa[0].address.pincode.trim() : '',
    });

  }
  useEffect(() => {
    campaignRef();
    if (token) {
      handleGetIcsData(localStorage.getItem("ics_number"));
    }
  }, [token]);

  return (
    <>
      <MetaData title="Nitya Seva - ISKCON of Attapur" />
      <PageHeader
        title="Nitya Seva form"
        imgSrc="/images/page-header/17.webp"
      />
      <section className="donation-form-sec" ref={formRef}>
        <div className="container">
          <div
            className="row d-flex justify-content-center mb-5"
            id="scrollToDonateFrom"
          >
            <div className="col-md-10">

              <figure className="mb-0">
                <img
                  className="w-100"
                  src="/images/campaign/nitya-seva-bvanner.jpg"
                  alt="Nitya Seva image"
                />
              </figure>
              <div id="scrollToDonationForm" className="heading">
                <h2 className="head-1 mt-3">NITYA SEVA OPPORTUNITY</h2>

                <p style={{ textAlign: 'justify' }}>Nitya Seva is a unique opportunity for devotees to serve the lotus feet of Sri Sri Radha Madhava through monthly donations. Your generous support helps  sustain our temple services and Food For Life program. Under this program, your desired amount will be automatically debited from your account each month. Your contributions make a significant impact on society.</p>
                <p>Support us in our mission of devotion and service by joining Nitya Seva today.</p>


              </div>
            </div>
          </div>
          <div
            className="form-wrap my-5"
            style={{ scrollMarginBlockStart: "150px" }}
          >
            <form onSubmit={handleSubmit(handleNityaSevaSub)}>
              <div className="row align-items-start">

                <div className="col-lg-7 mt-lg-0 mt-4 ">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-part">

                        <label htmlFor="">Donation For</label>

                        <input
                          type="text"
                          readOnly
                          defaultValue={'Nitya Seva'}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          {...register("schemaname", {
                            required: false,
                            //  onChange: (e) => {
                            //    handleSelectProfiles(e);
                            //  },
                          })}

                          placeholder=""
                        />
                        {errors.schemaname?.type === "required" && (
                          <small className="text-danger error">
                            This is required
                          </small>
                        )}
                        {errors.schemaname?.message && (
                          <small className="text-danger error">
                            {errors.schemaName?.message}
                          </small>
                        )}

                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-part">
                        <label htmlFor="">Amount</label>

                        <select
                          {...register("amount", {
                            required: true,
                            pattern: {
                              value: /^\d+$/,
                              message: "Amount must be number.",
                            },
                            max: {
                              value: 500000,
                              message: "Please enter a valid amount",
                            }
                          })}
                        >
                          <option value={''} defaultValue>
                            Select amount
                          </option>
                          {schemeData.map((item, i) => (
                            <option key={i} value={item.amount}>
                              {item.subtitle}
                            </option>
                          ))}
                        </select>
                        {errors.amount?.type === "required" && (
                          <small className="text-danger error">
                            This is required
                          </small>
                        )}
                        {errors.amount && (
                          <small className="text-danger error">
                            {errors.amount.message}
                          </small>
                        )}


                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-part">
                        <label htmlFor="">Mobile Number</label>
                        <input
                          type="tel"
                          placeholder="Phone Number"
                          //onKeyUp={getIcsData}
                          maxLength={10}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          readOnly={user ? true : false}
                          // disabled=
                          {...register("phone", {
                            required: true,
                            pattern: {
                              value: /^[6-9]\d*$/,
                              message: "Please provide valid phone number",
                            },
                            minLength: {
                              value: 10,
                              message: "Phone number must be minimum 10 digit",
                            },
                          })}
                        />
                        {errors.phone?.type === "required" && (
                          <small className="text-danger error">
                            This is required
                          </small>
                        )}
                        {errors.phone && (
                          <small className="text-danger error">
                            {errors.phone.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-part">
                        <label htmlFor="">Email</label>
                        <input
                          type="email"
                          {...register("email", {
                            required: true,
                            pattern: {
                              value:
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: "Please enter a valid email",
                            },
                          })}
                          placeholder="Email"
                        />
                        {errors.email?.type === "required" && (
                          <small className="text-danger error">
                            This is required
                          </small>
                        )}
                        {errors.email && (
                          <small className="text-danger error">
                            {errors.email.message}
                          </small>
                        )}
                      </div>
                    </div>
                    {!ics_data || ics_data == "" || !user ? (
                      <div className="col-md-6">
                        <div className="form-part">
                          <label htmlFor="">Name</label>
                          <input
                            maxLength={50}
                            type="text"
                            //onKeyUp={fetchNameFromIcs}
                            {...register("name", {
                              required: true,
                              maxLength: {
                                value: 50,
                                message:
                                  "Name can't exceeds more than 50 characters",
                              },
                              pattern: {
                                value: /^[A-Za-z ]*$/,
                                message: "Special Characters not allowed !",
                              },
                            })}
                            onKeyPress={(event) => {
                              if (/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            placeholder="Name"
                          />
                          {errors.name?.type === "required" && (
                            <small className="text-danger error">
                              This is required
                            </small>
                          )}
                          {errors.name?.message && (
                            <small className="text-danger error">
                              {errors.name?.message}
                            </small>
                          )}
                          {token ? (
                            <Link
                              className="donation-link"
                              onClick={handelOptions}
                            >
                              Select Options
                            </Link>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="col-md-6">
                        <div className="form-part">
                          <label htmlFor="">Select Name</label>
                          <select
                            {...register("name", {
                              required: true,
                              onChange: (e) => {
                                handleSelectProfiles(e);
                              },
                            })}
                          >
                            {user ?
                              <option value={user?.name} defaultValue>
                                {user?.name}
                              </option>
                              : ''}
                            {ics_data.map((data, i) => (
                              <option key={i} value={data.id}>
                                {data.name}
                              </option>
                            ))}
                          </select>
                          {errors.donationType?.type === "required" && (
                            <small className="text-danger error">
                              This is required
                            </small>
                          )}
                          <Link
                            className="donation-link"
                            onClick={handelOther}
                          // style={{fontSize:'18px'}}
                          >
                            To enter new name click here
                          </Link>
                        </div>
                      </div>
                    )}



                    <div className="col-md-6">
                      <div className="form-part">
                        <label htmlFor="">PAN (Optional)</label>
                        <Tooltip
                          title="For 80G reciept"
                          placement="bottom-start"
                        >
                          <input
                            type="text"
                            style={{ textTransform: "uppercase" }}
                            name=""
                            id=""
                            // onKeyUp={fetchPanFromIcs}
                            maxLength={10}
                            placeholder="PAN"
                            {...register("pan", {
                              pattern: {
                                value: /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/,
                                message: "Please enter valid PAN",
                              },
                            })}
                          />
                        </Tooltip>
                        {errors.pan?.type === "required" && (
                          <small className="text-danger error">
                            This is required
                          </small>
                        )}
                        {errors.pan && (
                          <small className="text-danger error">
                            {errors.pan.message}
                          </small>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-part">
                        <label htmlFor="">Address </label>
                        <Tooltip
                          title="For Prasadam please provide this"
                          placement="bottom-start"
                        >
                          <textarea
                            defaultValue={ics_address}
                            type="text"
                            {...register("address", {
                              minLength: {
                                value: 10,
                                message:
                                  "address must be minimum 10 characters",
                              },
                            })}
                            placeholder="Address"
                            {...register("address", {
                              required: true,
                            })}
                          ></textarea>
                        </Tooltip>
                        {errors.address?.type === "required" && (
                          <small className="text-danger error">
                            This is required
                          </small>
                        )}

                        {errors.address?.message && (
                          <small className="text-danger error">
                            {errors.address?.message}
                          </small>
                        )}
                        {/* {errors.address && (
                          <small className="error">
                            {errors.address.message}
                          </small>
                        )} */}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-part">
                        <label htmlFor="">PIN Code</label>
                        <input
                          defaultValue={ics_pincode}
                          type="text"
                          minLength={6}
                          maxLength={6}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          {...register("pin", {
                            pattern: {
                              value: /^\d+$/,
                              message: "PIN code must be number.",
                            },
                            onChange: (e) => {
                              setValue("state", "");
                              setValue("city", "");
                              if (e.target.value.length === 6) {
                                fetchStateCity(e.target.value);
                              }
                            },
                          })}
                          {...register("pin", {
                            required: true,
                          })}
                          placeholder="PIN Code"
                        />
                        {errors.address?.type === "required" && (
                          <small className="text-danger error">
                            This is required
                          </small>
                        )}
                        {errors.pin && (
                          <small className="text-danger error">
                            {errors.pin.message}
                          </small>
                        )}
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="type_checkbox mt-3">
                        <input
                          type="checkbox"
                          id="memoryOfSomeone"
                          {...register("memoryOfSomeone")}
                        />
                        <label htmlFor="memoryOfSomeone">
                          This Donation in the memory/honor of someone or performed on
                          a specific occasion
                        </label>
                      </div>
                      {showMemoryBox && (
                        <div className="row">
                          <div className="col-lg-2 col-md-6">
                            <div className="form-part">
                              <label htmlFor="mrandms">Title</label>
                              <select {...register("mrandms")}>
                                <option value="">
                                  Select Title
                                </option>
                                <option value="mrtitle">Mr</option>
                                <option value="mstitle">
                                  Ms
                                </option>
                                <option value="latere">
                                  Late
                                </option>

                              </select>

                              {errors.occasion?.message && (
                                <small className="text-danger error">
                                  {errors.occasion?.message}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-part">
                              <label htmlFor="inNameOf">Name </label>
                              <input
                                type="text"
                                placeholder="In Name Of"
                                {...register("inNameOf")}
                              />

                              {errors.inNameOf?.message && (
                                <small className="text-danger error">
                                  {errors.inNameOf?.message}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6">
                            <div className="form-part">
                              <label htmlFor="relationwithDonor">
                                Relation
                              </label>
                              <input
                                type="text"
                                placeholder="Relation"
                                {...register("relationwithDonor")}
                              />

                              {errors.relationwithDonor?.message && (
                                <small className="text-danger error">
                                  {errors.relationwithDonor?.message}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6">
                            <div className="form-part">
                              <label htmlFor="">Relative Mobile Number</label>
                              <input
                                type="tel"
                                placeholder="Phone Number"
                                // onKeyUp={getIcsData}
                                maxLength={10}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                // disabled=
                                {...register("relativephone", {

                                  pattern: {
                                    value: /^[6-9]\d*$/,
                                    message: "Please provide valid phone number",
                                  },
                                  minLength: {
                                    value: 10,
                                    message: "Phone number must be minimum 10 digit",
                                  },
                                })}
                              />

                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6">
                            <div className="form-part">
                              <label htmlFor="occasion">Occasion</label>
                              <select {...register("occasion")}>
                                <option value="birthday">
                                  Select Occasion
                                </option>
                                <option value="Birthday">Birthday</option>
                                <option value="MarriageAnniversary">
                                  Marriage Anniversary
                                </option>
                                <option value="DeathAnniversary">
                                  Death Anniversary
                                </option>
                                <option value="OtherOccasion">
                                  Any Other Occasion
                                </option>
                              </select>

                              {errors.occasion?.message && (
                                <small className="text-danger error">
                                  {errors.occasion?.message}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6">
                            <div className="form-part">
                              <label htmlFor="inDate">Occasion Date</label>
                              <div className="input-date">
                                <input
                                  type="date"

                                  placeholder="Date"
                                  {...register("inDate", { required: true })}
                                  min={getCurrentDate()}
                                ></input>
                              </div>
                            </div>
                          </div>
                          {/* <div className="form-part mt-2 memory_field">
                            <textarea
                              type="text"
                              {...register("memoryText")}
                            ></textarea>
                          </div> */}
                          <label htmlFor="">Message</label>
                          <textarea
                            type="text"
                            maxLength={250}
                            {...register("memoryText")}
                          ></textarea>
                          <small>* Minimum 250 characters allowed</small>
                        </div>
                      )}
                    </div>

                    <div className="col-12">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                        ref={recaptchaRef}
                        size="invisible"
                      />
                    </div>
                    {/* <div className="col-12 mt-4">
                      <button
                        type="submit"
                        className="box-hover custom-btn-cls donation_btn ms-0 donate-now-clicked-form"
                        disabled={loading ? true : false}
                      >
                        {loading ? (
                          <div className="d-flex align-items-center">
                            <div
                              className="spinner-border text-light"
                              role="status"
                            ></div>
                            <div className="ms-2">CONTRIBUTE...</div>
                          </div>
                        ) : (
                          <>

                            <>
                              CONTRIBUTE NOW &nbsp;&nbsp;
                              {totalAmount > 0 &&
                                `${totalAmount.toLocaleString("en-IN", {
                                  maximumFractionDigits: 0,
                                  style: "currency",
                                  currency: "INR",
                                })}/-`}
                            </>

                          </>
                        )}
                      </button>
                      {showOtp && (
                        <>
                          <div className="form-part d-inline-block my-2">
                            <input
                              type="text"
                              placeholder="Enter OTP"
                              maxLength={6}
                              minLength={6}
                              value={otp}
                              onChange={(e) => setOtp(e.target.value)}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {!showresendlink && showTimer && (
                              <span
                                style={{
                                  color: "#9F1D21",
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                {getTime(otpExp)}s
                              </span>
                            )}
                          </div>
                          <>
                            {showresendlink && (
                              <button
                                className="custom-btn-cls box-hover"
                                onClick={resendOtp}
                              >
                                RESEND
                              </button>
                            )}
                          </>
                        </>
                      )}
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-5 pe-xl-4">
                  <div className="container mt-2">
                    <div className="card" ref={campaignRef}>
                      <div className="card-body">
                        <h2 className="summary-header text-dark font-weight-bold">SUMMARY</h2>
                        <table className="table mt-4">
                          <thead>
                            <tr>
                              <th>SCHEME</th>
                              <th>SUBTOTAL</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Nitya Seva {totalAmount} &times; 1</td>
                              <td>₹{totalAmount} / month for 12 months</td>
                            </tr>
                            {/* <tr>
                              <td>Subtotal</td>
                              <td>₹{nityaAmount}</td>
                            </tr> */}
                            {/* <tr>
                              <td>Payment Gateway Fee</td>
                              <td>₹0</td>
                            </tr> */}
                            <tr className="font-weight-bold">
                              <td>Total</td>
                              <td>₹{totalAmount}</td>
                            </tr>
                          </tbody>
                        </table>
                        {/* <h3 className="summary-header text-danger font-weight-bold">Recurring totals</h3>
                        <table className="table mt-4">
                          <tbody>
                            <tr>
                              <td>Subtotal</td>
                              <td>₹5,000 / month for 12 months</td>
                            </tr>
                            <tr>
                              <td>Payment Gateway Fee</td>
                              <td>₹125</td>
                            </tr>
                            <tr className="font-weight-bold">
                              <td>Recurring total</td>
                              <td>₹5,125 / month for 12 months</td>
                            </tr>
                            <tr>
                              <td colSpan="2" className="text-muted" style={{ fontSize: '12px' }}>First renewal: July 25, 2024</td>
                            </tr>
                          </tbody>
                        </table> */}
                        <h4>Monthly Subscriptions</h4>
                        <p>Payment will be debited automatically every month for One Year</p>
                        <div className="col-12 mt-4">
                          <button
                            type="submit"
                            className="box-hover custom-btn-cls donation_btn ms-0 donate-now-clicked-form"
                            disabled={loading ? true : false}
                          >
                            {loading ? (
                              <div className="d-flex align-items-center">
                                <div
                                  className="spinner-border text-light"
                                  role="status"
                                ></div>
                                <div className="ms-2">CONTRIBUTE...</div>
                              </div>
                            ) : (
                              <>

                                <>
                                  CONTRIBUTE NOW &nbsp;&nbsp;
                                  {totalAmount > 0 &&
                                    `${totalAmount.toLocaleString("en-IN", {
                                      maximumFractionDigits: 0,
                                      style: "currency",
                                      currency: "INR",
                                    })}/-`}
                                </>

                              </>
                            )}
                          </button>
                          {showOtp && (
                            <>
                              <div className="form-part d-inline-block my-2">
                                <input
                                  type="text"
                                  placeholder="Enter OTP"
                                  maxLength={6}
                                  minLength={6}
                                  value={otp}
                                  onChange={(e) => setOtp(e.target.value)}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                                {!showresendlink && showTimer && (
                                  <span
                                    style={{
                                      color: "#9F1D21",
                                      display: "inline-block",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    {getTime(otpExp)}s
                                  </span>
                                )}
                              </div>
                              <>
                                {showresendlink && (
                                  <button
                                    className="custom-btn-cls box-hover"
                                    onClick={resendOtp}
                                  >
                                    RESEND
                                  </button>
                                )}
                              </>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="form-part mt-4 me-lg-2">
                    <div className="notes-wrap mt-0">
                      <p className="text-center">
                        <span> Please Note:</span> Complete Address with
                        PIN-Code and PAN is mandatory for an 80G Receipt.
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>
            </form>
          </div>
          {/* <div className="donate-note my-5">
            <p>
              Note: AVAIL 80G BENEFITS ON THE DONATIONS MADE TO ISKCON ATTAPUR (Preaching center- ISKCON HYDERABAD) AS PER THE INCOME TAX ACT 1961 AND RULES MADE THERE UNDER.

            </p>
            <p>
              "Exemption order ref no. AAATI0017PF2021901 dated 24/09/2021 valid
              upto 31/03/2026"
            </p>
          </div> */}
        </div>
      </section>
      <GoToTop top={top} />
    </>
  );
};

export default NityaSevaSubForm;

// We thank you for your commitment to contribute to the cause, on a regular basis.
