import PageHeader from "../components/common/PageHeader";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import GoToTop from "../helpers/GoToTop";
import MetaData from "../helpers/MetaData";
import { useForm } from "react-hook-form";
import ApiService from "../api/ApiService";
import notification from "../helpers/notification";
import ReCAPTCHA from "react-google-recaptcha";
import { useContext } from "react";
import AppContext from "../context/AppContext";
import EventsCardWrap from '../components/events/EventsCardWrap.jsx';
import AboutEvents from '../components/events/AboutEvents';

var preRegisterYatras = [
  {
    title: "Sri Dham|Mayapur",
    image: "/images/yatras/mayapur-card-min_1.webp",
    description:
      "Join us for a life-changing experience to the birthplace of Lord Chaitanya Mahaprabhu and the spiritual headquarters of the Hare Krishna Movement.",
  },
  {
    title: "Jagannath|Puri",
    image: "/images/yatras/jagannath-card-min.webp",
    description:
      "Join us as we make way to the courtyard of the Lord of the Universe, Puri where Lord Jagannath, his brother Balbhadra and sister Subhadra reside. ",
  },
  {
    title: "Tirupati|Balaji",
    image: "/images/yatras/tirupati-balaji-card-min_1.webp",
    description:
      "Join us to pay obeisances to Lord Venkateswara at the Tirupati Balaji temple in the Tirumala Hills of Andhra Pradesh.",
  },
  {
    title: "Kartik|Yatra",
    image: "/images/yatras/kartik-yatra-card-min-2.webp",
    description:
      "ISKCON of Bhiwandi celebrates the holy month of Kartik by organising a special Yatra  for devotees that helps deepen their connection with Hari. ",
  },
];

const RecentEvents = () => {
  const { user } = useContext(AppContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const recaptchaRef = useRef();

  const [show, setShow] = useState(false);
  const [selectedYatra, setSelectedyatra] = useState(null);

  const handlePreRegister = (yatra) => {
    setSelectedyatra(yatra);
    setShow(true);
  };
  const handleModalHide = () => {
    setShow(false);
  };
  const onFormSubmit = async (data) => {
    try {
      const token = await recaptchaRef.current.executeAsync();
      // console.log(token)
      data.token = token;

      const res = await ApiService.createYatraEnquiry({
        yatraName:
          selectedYatra?.title.split("|")[0] +
          " " +
          selectedYatra?.title.split("|")[1],
        ...data,
      });
      setShow(false);
      reset();
      notification("success", "Form submitted successfully!");
      recaptchaRef.current.reset();
    } catch (error) {
      console.log(error);
      notification('error', error.response.data.message)
    }
  };

  const [meta, setMeta] = useState(null);
  const fetchMeta = async (pagename) => {
    try {
      let res = await ApiService.fetchMetaData(pagename);
      setMeta(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user) {
      reset({
        name: user.name,
        phone: user.phone,
        email: user.email,
        address: user.address,
      });
    }
  }, [user]);

  useEffect(() => {
    fetchMeta("Dignitaries Visit");
  }, []);

  return (
    <>
      <MetaData title="Recent Events - ISKCON of Attapur" />
      <PageHeader title="Recent Events" imgSrc="/images/page-header/03.webp" />
      <AboutEvents />
      <EventsCardWrap /> 
      <GoToTop />
    </>
  );
};



export default RecentEvents;
