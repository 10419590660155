import PageHeader from "../components/common/PageHeader";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import GoToTop from "../helpers/GoToTop";
import MetaData from "../helpers/MetaData";
import { useForm } from "react-hook-form";
import ApiService from "../api/ApiService";
import notification from "../helpers/notification";
import ReCAPTCHA from "react-google-recaptcha";
import { useContext } from "react";
import AppContext from "../context/AppContext";
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Link, useNavigate } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton';


const DailyDarshan = () => {
  const ref = useRef()
  const { user } = useContext(AppContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [meta, setMeta] = useState(null);
  const fetchMeta = async (pagename) => {
    try {
      let res = await ApiService.fetchMetaData(pagename);
      setMeta(res);
    } catch (error) {
      console.log(error);
    }
  };



  const [Darshan, setDarshan] = useState([])
  const fetchDarshan = async () => {
    try {
      const res = await ApiService.fetchDarshan()
      //  console.log(res)
      setDarshan(res)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchDarshan()
  }, [])

  return (
    <>
      <MetaData title="Daily Darshan - ISKCON of Attapur" />
      <PageHeader title="Daily Darshan" imgSrc="/images/page-header/03.webp" />
      <section className="campaign-slider">
        <div className="container">
          <div className="heading">
            <h2 className="head-1">DAILY DARSHAN</h2>
          </div>
          <div className="card-wrap mt-5" style={{ border: '6px solid #F3B01B', height: '568x' }}>
            <Splide className='testimonial-slider' ref={ref} options={{
              perPage: 1,
              // type: 'loop',
              perMove: 1,
              focus: 'center',
              gap: 20,
              arrows: false,
              pagination: false,
              autoplay: true,
              speed: 1000,
              interval: 5000,
              breakpoints: {
                576: {
                  perPage: 1,
                  gap: 20,
                },
                768: {
                  perPage: 1,
                  trimSpace: true,
                },
                992: {
                  trimSpace: true,
                  perPage: 1,
                  gap: 20,
                },
                1024: {
                  trimSpace: true,
                  perPage: 1,
                  gap: 20,
                },
                1200: {
                  trimSpace: false,
                  perPage: 1
                },
                1400: {
                  trimSpace: false,
                  perPage: 1
                }
              }
            }}>
              {
                Darshan.length === 0 ?
                  <>

                    <SplideSlide>
                      <Skeleton height={375} />
                    </SplideSlide>

                  </> :
                  <>
                    {
                      Darshan.map((darshan) => (
                        darshan.image.map((image, index) => (
                          <SplideSlide key={index}>
                            <div>
                              <div className="card-bg" style={{ height: '564px' }}>
                                <figure>
                                  <img src={`${process.env.REACT_APP_S3_URL}/uploads/darshan/${image}`} alt="" />
                                </figure>
                              </div>
                            </div>
                          </SplideSlide>
                        ))
                      ))
                    }

                  </>
              }


            </Splide>

          </div>
        </div>
        <div className="arrow-wrap">
          <button className="slider-btn prev box-hover" onClick={() => ref.current.splide.go('-1')} ><FiChevronLeft /></button>
          <button className="slider-btn next box-hover" onClick={() => ref.current.splide.go('+1')}><FiChevronRight /></button>
        </div>

      </section>

      <GoToTop />
    </>
  );
};



export default DailyDarshan;
