import { Link } from 'react-router-dom'

const WelcomeToIskcon = () => {
  return (
    <section className="welcome-iskcon">
        <div className="container">
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col-md-10 ">
                    <div className="welcome-content">
                        <div className="heading">
                            <h2 className="head-1">HARE KRISHNA!</h2>
                   
                            <h2 className="head-2">WELCOME TO ISKCON ATTAPUR!</h2>
                        </div>
                        <p>Welcome to the abode of Sri Sri Radha Madhava, where the most-benevolent Lord has come to receive your love and to fill your lives with His grace. As devotees of the Lord we are here to serve you and look forward to welcoming you at our temple. 
                        </p>
                        <div className="welcome-link d-flex align-items-center justify-content-center">
                        <Link to="/about-us/#temple">LEARN MORE ABOUT SRI SRI RADHA MADHAVA TEMPLE</Link>
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default WelcomeToIskcon