import GoToTop from "../helpers/GoToTop";
import MetaData from "../helpers/MetaData";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
    return ( 
        <>
         <MetaData title="Privacy Policy" />
         <section className="privacy-policy-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <div className="heading">
                                <h2 className="head-1">PRIVACY POLICY</h2>
                                <div className="privacy-para">
                                        <h5>USER INFORMATION</h5>
                                        <p>To avail certain services on  
                                            <a href="/"> iskconattapur.com</a> , users are required to provide certain information for the registration process namely: – a) your name, b) email address, c) sex, d) age, e) PIN code, f) credit card or debit card details etc., and / or your occupation, interests, and the like. The Information as supplied by the users enables us to improve our sites and provide you the most user-friendly experience.
                                        </p>

                                        <p> 
                                            All required information is service dependent and we may use the above said user information to maintain, protect, and improve its services (including advertising services) and for developing new services.
                                        </p>

                                        <p>
                                            Such information will not be considered as sensitive if it is freely available and accessible in the public domain or is furnished under the Right to Information Act, 2005 or any other law for the time being in force.
                                        </p>
                                </div>
                                <div className="privacy-para">
                                        <h5>COOKIES</h5>
                                        <p>To improve the responsiveness of the sites for our users, we may use “cookies”, or similar electronic tools to collect information to assign each visitor a unique, random number as a User Identification (User ID) to understand the user’s individual interests using the Identified Computer. Unless you voluntarily identify yourself (through registration, for example), we will have no way of knowing who you are, even if we assign a cookie to your computer. The only personal information a cookie can contain is information you supply (an example of this is when you ask for our Personalised Horoscope). A cookie cannot read data off your hard drive. Our advertisers may also assign their own cookies to your browser (if you click on their ads), a process that we do not control.
                                        </p>

                                        <p> 
                                        Our web servers automatically collect limited information about your computer’s connection to the Internet, including your IP address, when you visit our site. (Your IP address is a number that lets computers attached to the Internet know where to send you data — such as the web pages you view.) Your IP address does not identify you personally. We use this information to deliver our web pages to you upon request, to tailor our site to the interests of our users, to measure traffic within our site and let advertisers know the geographic locations from where our visitors come.
                                        </p>

                                        
                                </div>
                                <div className="privacy-para">
                                        <h5>LINKS TO THE OTHER SITES</h5>
                                        <p>Our policy discloses the privacy practices for our own web site only. Our site provides links to other websites also that are beyond our control. We shall in no way be responsible in way for your use of such sites.
                                        </p>   
                                </div>
                                <div className="privacy-para">
                                        <h5>INFORMATION SHARING</h5>
                                        <p>We shares the sensitive personal information to any third party without obtaining the prior consent of the user in the following limited circumstances:
                                        </p>   
                                        <p>(a) When it is requested or required by law or by any court or governmental agency or authority to disclose, for the purpose of verification of identity, or for the prevention, detection, investigation including cyber incidents, or for prosecution and punishment of offences. These disclosures are made in good faith and belief that such disclosure is reasonably necessary for enforcing these Terms; for complying with the applicable laws and regulations.
                                        </p>   
                                        <p>(b) We propose to share such information within its group companies and officers and employees of such group companies for the purpose of processing personal information on its behalf. We also ensure that these recipients of such information agree to process such information based on our instructions and in compliance with this Privacy Policy and any other appropriate confidentiality and security measures.
                                        </p>   
                                </div>
                                <p>Further, We will use your information to respond to you, regarding the reason you contacted us. We will not share your information with any third party outside of our organization, other than as necessary to fulfill your request, e.g. to ship an order.</p>
                                <p>Unless you ask us not to, we may contact you via sms, whatsapp or email in the future to tell you about specials events, new products or services, or changes to this privacy policy. You may opt out of any future contacts from us at any time. You can do the following at any time by contacting us via the email address or phone number given on our website:</p>
                                <ol>
                                    <li>See what data we have about you, if any.</li>
                                    <li>Change/correct any data we have about you.</li>
                                    <li>Have us delete any data we have about you.</li>
                                    <li>Express any concern you have about our use of your data.</li>
                                </ol>
                                <div className="privacy-para">
                                        <h5>INFORMATION SECURITY</h5>
                                        <p>We take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure or destruction of data. These include internal reviews of our data collection, storage and processing practices and security measures, including appropriate encryption and physical security measures to guard against unauthorized access to systems where we store personal data.
                                        </p>   
                                        <p>All information gathered on our website <a href="/">www.iskconattapur.com</a>  is securely stored within our controlled database. The database is stored on servers secured behind a firewall; access to the servers is password-protected and is strictly limited.
                                        </p>   
                                        <p>While we use encryption to protect sensitive information transmitted online, we also protect your information offline. Only employees who need the information to perform a specific job (for example, billing or customer service) are granted access to personally identifiable information. The computer servers in which we store personally identifiable information are kept in a highly secure environment.</p>
                                        <p>However, as effective as our security measures are, no security system is impenetrable. We cannot guarantee the security of our database, nor can we guarantee that information you supply will not be intercepted while being transmitted to us over the Internet. And, of course, any information you include in a posting to the discussion areas is available to anyone with Internet access.</p>
                                        <p>However, the internet is an ever-evolving medium. We may change our Privacy Policy from time to time to incorporate necessary future changes. Of course, our use of any information we gather will always be consistent with the policy under which the information was collected, regardless of what the new policy may be.</p>
                                </div>
                                <p>If you feel that we are not abiding by this privacy policy, you should contact our Grievance Officer immediately via email at <a href="mailto:iskconattapur@gmail.com"></a> iskconattapur@gmail.com .</p>
                                <div className="privacy-para">
                                        <h5>Grievance Redressal</h5>
                                        <p>Redressal Mechanism: Any complaints, abuse or concerns with regards to content and or comment or breach of these terms shall be immediately informed to the designated Grievance Officer as mentioned below via in writing or through email signed with the electronic signature to
                                        </p>
                                        <br />
                                        <p>The Grievance Officer</p>   
                                        <p>ISKCON Attapur</p>
                                        <p><a href="/">www.iskconattapur.com</a></p>
                                        <p> ISKCON Property</p>
                                       
                                        <p>Plot No.220, MIG, Mushk Mahal Residential Complex,</p>
                                        <p>HUDA Colony, Attapur,  GHMC Circle, Hyderabad- Telangana - 500048</p>
                                        <p><a href="mailto:iskconattapur@gmail.com">Email: iskconattapur@gmail.com</a></p>
                                </div>
                                <div className="terms-and-condition-btn mt-5">
                                    <Link className="custom-btn-cls box-hover" to="/privacy-policy">Privacy Policy</Link>
                                    <Link className="custom-btn-cls box-hover" to="/refund-policy">Refund policy</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </section>
         <GoToTop />
        </>
     );
}
 
export default PrivacyPolicy;