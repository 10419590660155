import PageHeader from "../components/common/PageHeader"
import GoToTop from "../helpers/GoToTop";
import MetaData from "../helpers/MetaData";
import { useEffect, useState } from "react";
import WallpaperCard from "../components/wallpapers/WallpaperCard";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import notification from "../helpers/notification";
import ApiService from "../api/ApiService";
import Skeleton from "react-loading-skeleton";

const Wallpapers = () => {

    const [wallpapers, setWallpapers] = useState([])

    const fetchWallpapers = async () => {
        try {
            let res = await ApiService.fetchWallpapers()
            setWallpapers(res)
        } catch (error) {
            console.log(error)
            notification('error', 'Unable to fetch wallpapers')
        }
    }

    useEffect(() => {
        fetchWallpapers()
    }, [])

    return ( 
        <>
        <MetaData title="Wallpapers" />
        <PageHeader title=" KRISHNA KEEPSAKE" imgSrc="/images/page-header/21.webp" />
        <section className="wallpaper-head-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="heading">
                                <h2 className="head-1">
                                KRISHNA KEEPSAKE
                                </h2>
                                <p>
                                Welcome Krishna into your home and heart by these enchanting wall papers. Fill your life with the Lord and His divine grace.
                                </p>
                            </div>
                        </div>
                    </div>
                   
                </div>
        </section>
        <section className="wallpaper-wrap px-1">
            {
                wallpapers?.length == 0 ?
                    <div className="row">
                        <div className="col-lg-4 col-md-6 mb-5">
                            <Skeleton height={350}/>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-5">
                            <Skeleton height={350}/>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-5">
                            <Skeleton height={350}/>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-5">
                            <Skeleton height={350}/>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-5">
                            <Skeleton height={350}/>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-5">
                            <Skeleton height={350}/>
                        </div>
                    </div>
                :
                <ResponsiveMasonry
                    columnsCountBreakPoints={{0: 1, 576: 2, 1024: 3}}
                >
                    <Masonry>
                        {
                            wallpapers.map(item => (
                                <WallpaperCard key={item._id} title=" KRISHNA KEEPSAKE" thumbnailImgUrl={`${process.env.REACT_APP_S3_URL}/uploads/wallpapers/${item.thumbnailImgUrl}`} imgSrc={`${process.env.REACT_APP_S3_URL}/uploads/wallpapers/${item.imgUrl}`} />
                            ))
                        }
                    </Masonry>
                </ResponsiveMasonry>
            }

        </section>
        </>
     );
}
 
export default Wallpapers;