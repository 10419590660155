import PageHeader from "../components/common/PageHeader";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import GoToTop from "../helpers/GoToTop";
import MetaData from "../helpers/MetaData";
import { useForm } from "react-hook-form";
import ApiService from "../api/ApiService";
import notification from "../helpers/notification";
import ReCAPTCHA from "react-google-recaptcha";
import { useContext } from "react";
import AppContext from "../context/AppContext";
import AboutYatras from '../components/yatras/AboutYatras';
import YatrasCardWrap from '../components/yatras/YatrasCardWrap.jsx';
import moment from 'moment';


import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

var preRegisterYatras = [
  {
    title: "Sri Dham|Mayapur",
    image: "/images/yatras/mayapur-card-min_1.webp",
    description:
      "Join us for a life-changing experience to the birthplace of Lord Chaitanya Mahaprabhu and the spiritual headquarters of the Hare Krishna Movement.",
  },
  {
    title: "Jagannath|Puri",
    image: "/images/yatras/jagannath-card-min.webp",
    description:
      "Join us as we make way to the courtyard of the Lord of the Universe, Puri where Lord Jagannath, his brother Balbhadra and sister Subhadra reside. ",
    register: "0",
  },
  {
    title: "Tirupati|Balaji",
    image: "/images/yatras/tirupati-balaji-card-min_1.webp",
    description:
      "Join us to pay obeisances to Lord Venkateswara at the Tirupati Balaji temple in the Tirumala Hills of Andhra Pradesh.",
    register: "0",
  },
  {
    title: "Kartik|Yatra",
    image: "/images/yatras/kartik-yatra-card-min-2.webp",
    description:
      "ISKCON of Attapur celebrates the holy month of Kartik by organising a special Yatra  for devotees that helps deepen their connection with Hari. ",
    register: "0",
  },
];

const Yatras = () => {
  const scrollToElement = () => {
    const element = document.getElementById('scrollToAllPages');
    // console.log("elements",element);
    if(element) {
      element.scrollIntoView({behavior : 'smooth'})
    }
  };
  const { user } = useContext(AppContext);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const recaptchaRef = useRef();

  const [show, setShow] = useState(false);
  const [selectedYatra, setSelectedyatra] = useState(null);
  const [pric, setPrice] = useState(null);
  const [TotalPrice, setTotalPrice] = useState(null);
  const [YatraPrice, setYatraPrice] = useState(null);
  const [noOfDays, setDays] = useState(null);
  const [Occupancy, setOccupancy] = useState(null);
  const [People, setPeople] = useState(null);
  const [pay_id, setPaymentid] = useState(null);
  const [price, setFinal] = useState('0');
  const [part_pay, setPartPay] = useState('fullpay');
  const [errorMinPrice, setErrorMinPrice] = useState(null);
  const [handelrooms, setHandelrooms] = useState(null);
  const [displayrooms, setDisplayrooms] = useState(0);
  const [displaychild, setDisplaychild] = useState(1);
  const [noOfrooms, setHandleNoOfrooms] = useState(0);
  const [noofchild, setHandleChild] = useState(0);
  const [disabledBtn, setDisabledBtn] = useState(false);

  const [leftamount, setLeftAmount] = useState(0);
  const [allDisabled, setAllDisabled] = useState(false);




  const [fetch_day, fetchSetdays] = useState(0);
  const [fetch_adult, fetchSetadults] = useState(0);

  const [fetch_child, fetchSetChild] = useState(0);
  const [fetch_rooms, fetchSetRooms] = useState(0);

  const [fetch_occupancy, fetchSetOccupancy] = useState(null);
  const [fetch_totalamount, fetchSetTotalAmount] = useState(0);

  const [fetch_paidamount, fetchSetPaidAmount] = useState(0);

  const [yatra_id, setYatraid] = useState(0);



  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const handlePreRegister = (yatra) => {
    setSelectedyatra(yatra);
    setShow(true);
  };
  const handleModalHide = () => {
    setShow(false);
  };



  let Childcost = 0

  Childcost = 1000 * noofchild




  //const handleCalculation = () => {
  let accomodation = 0

  if (Occupancy === 'Personal') {
    accomodation = pric * noOfDays * noOfrooms
  } else {
    accomodation = pric * noOfDays * People
  }


  let food = 0

  food = 800 * noOfDays * People



  const totalPrice = accomodation + food + Childcost

  console.log(accomodation)


  console.log(food + '--' + accomodation + '----' + Childcost)
  console.log(Childcost)

  // }



  const handleDays = (e) => {
    setDays(e.target.value);
    // setYatraPrice(e.target.value * 1000);
    setHandleNoOfrooms('');
    setHandleChild('');
    // alert(YatraPrice)
    // localStorage.removeItem("user");;
    // setPrice(e.target.value * 1000);
    setOccupancy('');
    // setFinal(e.target.value * 1000)
    setPeople('');
  };


  const handleOccupancy = (e) => {
    // setOccupancy(e.target.value);   
    // localStorage.removeItem("user");;

    if (e.target.value === 'Personal') {
      setDisplayrooms(1)
      setDisplaychild(1)
      //setFinal(2200 * noOfDays + YatraPrice * noOfrooms);
      setPrice(2200);
    } else if (e.target.value === 'Double') {
      setDisplayrooms(0)
      setDisplaychild(0)
      //setFinal(1100 * noOfDays + YatraPrice);
      setPrice(1100);
      setHandleChild('');
    } else if (e.target.value === 'Tripple') {
      setDisplayrooms(0)
      setDisplaychild(0)
      //setFinal(733 * noOfDays + YatraPrice);
      setPrice(733);
      setHandleChild('');
    } else if (e.target.value === 'Quadruple') {
      setDisplayrooms(0)
      setDisplaychild(0)
      setHandleChild('');
      //setFinal(550 * noOfDays + YatraPrice);
      setPrice(550);
    } else {
      setDisplaychild(1)
      setPrice(0);
      setDisplayrooms(0)
    }
    setOccupancy(e.target.value);
    // setPeople(''); 
  };


  const handlePeople = (e) => {
    setPeople(e.target.value);
    //  setFinal(e.target.value * pric)
  };

  const handleNoOfrooms = (e) => {
    setHandleNoOfrooms(e.target.value);
  }

  const handleChild = (e) => {
    setHandleChild(e.target.value);
    //handleCalculation()
  }


  const onFormSubmitt = async (data) => {
    try {
      const token = await recaptchaRef.current.executeAsync();
      // console.log(token)
      data.token = token;

      const pay_idd = localStorage.getItem('my-key');

      const res = await ApiService.createYatraEnquiry({
        yatraName:
          selectedYatra?.title.split("|")[0] +
          " " +
          selectedYatra?.title.split("|")[1],
        ...data,
        noOfAccompanyingPeople: People,
        noOfDays: noOfDays,
        Occupancy: Occupancy,
        noOfRooms: noOfrooms,
        noOfChild: noofchild,
        TotalAmount: totalPrice,
        PaidAmount: part_pay === 'fullpay' ? totalPrice : data.partPayment,
        Payment_id: pay_idd,
      });


      setShow(false);
      reset();
      notification("success", "Form submitted successfully!");
      recaptchaRef.current.reset();
      localStorage.removeItem('my-key');
      setHandleChild('');
      setPeople('');
      setTotalPrice('');

    } catch (error) {
      console.log(error);
      notification('error', error.response.data.message)
    }
  };

  console.log(noofchild)

  const handleRooms = (e) => {
    setHandelrooms(e.target.value);
  }



  //console.log(totalPrice)


  const onFormSubmit = async (data) => {
    let partAmount = 0

    if (leftamount > 0) {
      partAmount = leftamount
    } else {
      partAmount = data?.partPayment
    }

    const res = await ApiService.createYatraEnquiry({
      ...data,
      amount: part_pay === 'fullpay' ? totalPrice : partAmount,
    });

    //console.log(res)

    try {
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        currency: res.currency,
        amount: res.amount,
        name: process.env.REACT_APP_PROJECT_NAME,
        description: "Help Us to Help the Society",
        image: `${process.env.REACT_APP_S3_URL}/assets/img/logo.png`,
        order_id: res.id,
        handler: function (response) {

          if (leftamount > 0) {
            notification("success", "Payment Completed");
            fetchYatraEnqUpdate(yatra_id)
            setShow(false);
            setAllDisabled(false);
            setTotalPrice(0);
            reset({
              name: "",
              phone: "",
              email: "",
              location: "",
              age: "",
              gender: "",
              preacherName: "",
            });
          } else {

            notification("success", "Payment successfull!");
            setPaymentid(response.razorpay_payment_id)
            const textForStorage = response.razorpay_payment_id
            localStorage.setItem('my-key', textForStorage);
            const textFromStorage = localStorage.getItem('my-key');
            if (textFromStorage) {
              onFormSubmitt(data);
            }
          }

        },
        prefill: {
          name: data?.name,
          email: data?.email,
          contact: data?.phone,
        },
        notes: {
          type: "donation",
        },
        theme: {
          color: "#FFD370",
        },
        modal: {
          ondismiss: async function () {
            const cancelDonation = await ApiService.cancelDonation(res.id);

          },
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();

    } catch (error) {
      console.log(error);
      notification('error', error.response.data.message)
    }


  };

  const [meta, setMeta] = useState(null);
  const fetchMeta = async (pagename) => {
    try {
      let res = await ApiService.fetchMetaData(pagename);
      setMeta(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
    if (user) {
      reset({
        name: user.name,
        phone: user.phone,
        email: user.email,
        address: user.address,
      });
    }
  }, [user]);

  useEffect(() => {
    fetchMeta("yatras");
  }, []);
  useEffect(()=>{

    scrollToElement();
    },[])
  const [Yatras, setYatras] = useState([])
  const fetchYatras = async () => {
    try {
      const res = await ApiService.fetchYatras()
      console.log(res)
      setYatras(res)
    } catch (error) {
      console.log(error)
    }
  }


  const fetchYatraEnq = async (data) => {
    try {
      const res = await ApiService.fetchYatraEnq(data)
      console.log(res)
      if (res[0].RemainingAmount) {
        setYatraid(res[0]._id)
        setAllDisabled(true)
        setPartPay('partpay')
        setLeftAmount(res[0].RemainingAmount)

        fetchSetdays(res[0].noOfDays)
        fetchSetadults(res[0].noOfAccompanyingPeople)

        fetchSetChild(res[0].noOfChild > 0 ? res[0].noOfChild : 0)
        fetchSetRooms(res[0].noOfRooms > 0 ? res[0].noOfRooms : 0)

        fetchSetOccupancy(res[0].Occupancy)
        fetchSetTotalAmount(res[0].TotalAmount)

        fetchSetPaidAmount(res[0].PaidAmount)
        reset({
          name: res[0].name,
          email: res[0].email,
          location: res[0].location,
          age: res[0].age,
          gender: res[0].gender,
          preacherName: res[0].preacherName,
        });



      } else {

        setAllDisabled(false)
        setPartPay('fullpay')
        setLeftAmount(0)
        reset({
          name: user.name,
          email: user.email,
          phone: user.phone,
          location: "",
          age: "",
          gender: "",
          preacherName: "",
        });

      }
      //setYatras(res)   
    } catch (error) {
      console.log(error)
    }
  }

  const fetchYatraEnqUpdate = async (data) => {
    try {
      let AllData = {
        id: yatra_id,
        amt: fetch_totalamount
      }
      const res = await ApiService.fetchYatraEnqUpdate(AllData)
      console.log(res)
    } catch (error) {
      console.log(error)
    }
  }








  useEffect(() => {
    fetchYatras()
  }, [])


  const handelPartPay = (e) => {
    setPartPay(e.target.value)

  }

  const handleMinPriceKeyUp = (e) => {
    if (e.target.value < selectedYatra.minPrice) {
      setDisabledBtn(true)
      setErrorMinPrice('Please Enter Minimum Amount Rs.' + selectedYatra.minPrice);
    } else {
      setDisabledBtn(false)
      setErrorMinPrice('');
    }


  }


  const PhoneCheck = (e) => {
    const val = e.target.value.length;

    if (val === 10) {

      fetchYatraEnq(e.target.value)


    }
    else {

      setAllDisabled(false)
      setPartPay('fullpay')
      setLeftAmount(0)
      reset({
        name: user.name,
        email: user.email,
        phone: user.phone,
        location: "",
        age: "",
        gender: "",
        preacherName: "",
      });


    }




  }








  return (
    <>
      <MetaData title="Yatras - ISKCON of Attapur" />
      <PageHeader title="Yatras" imgSrc="/images/page-header/03.webp" />
      <AboutYatras />


      <section className="vrindavan-card">
        <div className="container">
          {

            !(Yatras.length > 0) ? <YatrasCardWrap /> : Yatras.map((Yatra, index) => {
              return (
                <>
                  {

                    Yatra.status === true ?
                      <div key={index} className="vrindavan-wrap mt-4">
                        <div className="row" id="scrollToAllPages">
                          <div className="col-lg-5">
                            <figure className="yatra-fig">
                              <img src={`${process.env.REACT_APP_S3_URL}/uploads/yatra/${Yatra?.image}`} alt="" />
                            </figure>
                          </div>
                          <div className="col-lg-7">
                            <div className="heading my-auto">
                              <div className="mb-md-2 mt-md-0 mt-4">
                                <h2 className="head-2">{Yatra?.title} </h2>
                                {/* <h2 className="head-1">{Yatra?.title}</h2> */}
                              </div>
                              <div className="date-div mb-md-2">
                                <figure className="date-up">
                                  <img src="/images/yatras/up.svg" alt="" />
                                </figure>
                                <h5>{
                                  moment(Yatra.startDate).format('DD/MM/YYYY') === moment(Yatra.endDate).format('DD/MM/YYYY') ?
                                    moment(Yatra.startDate).format('dddd, D MMMM YYYY') :
                                    moment(Yatra.startDate).format('dddd, D MMMM') + " - " + moment(Yatra.endDate).format('dddd, D MMMM YYYY')
                                }</h5>
                                <figure className="date-down">
                                  <img src="/images/yatras/down.svg" alt="" />
                                </figure>
                              </div>
                              <div>
                                <p>
                                  {Yatra?.description}
                                </p>
                                <p className="my-1">
                                  Join us for the {Yatra?.title}
                                </p>
                                <p className="my-1">

                                  Between {
                                    moment(Yatra.startDate).format('DD/MM/YYYY') === moment(Yatra.endDate).format('DD/MM/YYYY') ?
                                      moment(Yatra.startDate).format('dddd, D MMMM YYYY') :
                                      moment(Yatra.startDate).format('dddd, D MMMM') + " - " + moment(Yatra.endDate).format('dddd, D MMMM YYYY')
                                  }
                                </p>

                              </div>

                              <>
                                {
                                  Yatra.androidCalenderLink === '1' ? <button onClick={() => handlePreRegister({ minPrice: Yatra?.minAmount, title: Yatra?.title, image: `${process.env.REACT_APP_S3_URL}/uploads/yatra/${Yatra?.image}`, })} className="custom-btn-cls box-hover my-4">
                                    REGISTER
                                  </button> :
                                    <button className="custom-btn-cls box-hover my-4" style={{ width: '275px' }}>Registration will open soon...</button>
                                }
                              </>

                            </div>
                          </div>
                        </div>
                      </div>

                      : ''
                  }
                </>
              )
            })
          }
        </div>
      </section>



      {/* <section className="yatras-wrap">
        <div className="container">
          <div className="yatras-wrap">
            <div className="row my-5">
            {

!(Yatras.length > 0) ? <YatrasCardWrap />:Yatras.map((Yatra, index) => {
  return (
    <>
     {
                <div key={index} className="col-xl-3 col-lg-4 col-md-6 mb-5">
                  <div className="yatra-card">
                    <div className="yatra-top">
                      <figure>
                        <img src={`${process.env.REACT_APP_S3_URL}/uploads/yatra/${Yatra?.image}`} alt="" />
                      </figure>
                      <h5 className="text-uppercase">
                      {Yatra?.title}
                      </h5>
                      {Yatra.androidCalenderLink === '1'?
                     ''
                     :
                     <p> To be Announced.</p>
                     }
                     
                    </div>
                    <div className="book-btn">
                      <button
                        onClick={() => handlePreRegister(Yatra)}
                        className="box-hover"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        PRE-REGISTER NOW{" "}
                      </button>
                    </div>
                  </div>
                </div>
             }
             </>
             )
             })
             }
            </div>
          </div>
        </div>
      </section> */}

      {/* vrindavanDham1 */}
      <Modal centered show={show} onHide={handleModalHide}>
        <div className="modal-wrap ">
          <button className="btn close-svg" onClick={handleModalHide}>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 16 16"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"
              ></path>
            </svg>
          </button>

          <div className="row">
            <div className="col-md-5 d-sm-block d-none">
              <figure>
                <img src={selectedYatra?.image} alt={selectedYatra?.title} />
              </figure>
            </div>
            <div className="col-md-7">
              <div className="pop-up-form">
                <div className="pop-up-top">
                  <div className="heading">
                    <h2 className="head-2">
                      {selectedYatra?.title.split("|")[0]}
                    </h2>
                    <h2 className="head-1">
                      {selectedYatra?.title.split("|")[1]}
                    </h2>
                  </div>
                  <figure className="hare-krishna-logo-fig">
                    <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                  </figure>
                </div>

                <p>{selectedYatra?.description}</p>



                <form
                  action=""
                  className="mt-3 mt-sm-0"
                  onSubmit={handleSubmit(onFormSubmit)}
                >
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="label-input-wrap">
                        <label htmlFor="name">Name</label>
                        <input
                          type="text"
                          id="name"
                          disabled={allDisabled}
                          {...register("name", { required: true })}
                          onKeyPress={(event) => {
                            if (/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {errors.name?.type === "required" && (
                          <small className="error">This is required</small>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="label-input-wrap">
                        <label htmlFor="email">Email</label>
                        <input
                          type="text"
                          id="email"
                          disabled={allDisabled}
                          {...register("email", {
                            required: true,
                            pattern: {
                              value:
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: "Please enter a valid email",
                            },
                          })}
                        />
                        {errors.email?.type === "required" && (
                          <small className="error">This is required</small>
                        )}
                        {errors.email && (
                          <small className="text-danger error">
                            {errors.email.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="label-input-wrap">
                        <label htmlFor="phone">Phone</label>
                        <input
                          type="text"
                          id="phone"
                          onKeyUp={PhoneCheck}
                          maxLength={10}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          {...register("phone", {
                            required: true,
                            pattern: {
                              value: /^[6-9]\d*$/,
                              message: "Please provide valid phone number",
                            },
                            minLength: {
                              value: 10,
                              message: "Phone must be minimum 10 digit",
                            },
                            maxLength: {
                              value: 10,
                              message: "Phone must be maximum 10 digit",
                            },
                          })}
                        />
                        {errors.phone?.type === "required" && (
                          <small className="error">This is required</small>
                        )}
                        {errors.phone && (
                          <small className="error">
                            {errors.phone.message}
                          </small>
                        )}
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="label-input-wrap">
                        <label htmlFor="location">Location</label>
                        {/* <select id="location" {...register("location",{required:true})}>
                                                <option value="">Select location</option>
                                                <option value="Bhiwandi">Bhiwandi</option>
                                                <option value="Thane">Thane</option>
                                                <option  value="Mumbai">Mumbai</option>
                                                </select> */}
                        <input
                          type="text"
                          id="location"
                          disabled={allDisabled}
                          {...register("location", { required: true })}
                        />
                        {errors.location?.type === "required" && (
                          <small className="error">This is required</small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="label-input-wrap">
                        <label htmlFor="">Age</label>
                        <input
                          type="text"
                          disabled={allDisabled}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          {...register("age", {
                            required: true,
                            pattern: {
                              value: /^\d+$/,
                              message: "Age must be number.",
                            },
                          })}
                        />
                        {errors.age?.type === "required" && (
                          <small className="error">This is required</small>
                        )}
                        {errors.age && (
                          <small className="error">{errors.age.message}</small>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="label-input-wrap">
                        <label htmlFor="gender">Gender</label>
                        <select
                          id="gender"
                          disabled={allDisabled}
                          {...register("gender", { required: true })}
                        >
                          <option value="">Select a gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                        {errors.gender?.type === "required" && (
                          <small className="error">This is required</small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="label-input-wrap">
                      <label htmlFor="preacherName">Preacher Name:</label>
                      <select id="preacherName" disabled={allDisabled} {...register("preacherName")}>
                        <option value="">Select a preacher</option>
                        <option value="Bhavamaya Nityananda Das">
                          Bhavamaya Nityananda Das
                        </option>
                        <option value="Chaitanya Avatar Das">
                          Chaitanya Avatar Das
                        </option>
                        <option value="Ishan Gaur Das">Ishan Gaur Das</option>
                        <option value="Krishna Premananda Das">
                          Krishna Premananda Das
                        </option>
                        <option value="Mahapati Ram Das">
                          Mahapati Ram Das
                        </option>
                        <option value="Narottam Priya Das">
                          Narottam Priya Das
                        </option>
                        <option value="Nitai Jivan Das">Nitai Jivan Das</option>
                        <option value="Nitai Madhava Das">
                          Nitai Madhava Das
                        </option>
                        <option value="Radhakant Das">Radhakant Das</option>
                        <option value="Shantivardhan Das">
                          Shantivardhan Das
                        </option>
                        <option value="Sitaram Das">Sitaram Das</option>
                        <option value="SriSudama Das">SriSudama Das</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>

                  </div>
                  {allDisabled == true ?
                    <>
                      <div className="row mt-3">
                        <div className="col-sm-6">
                          <div className="label-input-wrap">
                            <label>No. Of Days:</label>
                            {fetch_day}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="label-input-wrap">
                            <label>No. Of Adults:</label>
                            {fetch_adult}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="label-input-wrap">
                            <label>No. Of Child:</label>
                            {fetch_child}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="label-input-wrap">
                            <label>No. Of Rooms:</label>
                            {fetch_rooms}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="label-input-wrap">
                            <label>Occupancy:</label>
                            {fetch_occupancy}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="label-input-wrap">
                            <label>Total Amount:</label>
                            {fetch_totalamount}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="label-input-wrap">
                            <label>Paid Amount:</label>
                            {fetch_paidamount}
                          </div>
                        </div>
                      </div>

                      <div className="label-input-wrap">
                        <label>
                          Remaining Amount: ₹{leftamount}
                        </label>
                      </div>

                    </>

                    :
                    <>
                      <div>

                        <div className="label-input-wrap">
                          <label htmlFor="noOfDays">
                            No of Days:
                          </label>
                          <select
                            id="noOfDays"
                            disabled={allDisabled}
                            onChange={handleDays}
                            //defaultValue={}
                            required>
                            <option value="">Select Days</option>
                            {Array.from(Array(5).keys(), (val) => val + 1).map((val) => (
                              <option key={val} value={val}>
                                {val}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="label-input-wrap">
                          <label htmlFor="Occupancy">Occupancy:</label>
                          <select value={Occupancy} disabled={allDisabled} onChange={handleOccupancy} id="Occupancy">
                            <option value="0">Select Occupancy</option>
                            <option value="Personal">
                              Personal
                            </option>
                            <option value="Double">
                              Double
                            </option>
                            <option value="Tripple">
                              Tripple
                            </option>
                            <option value="Quadruple">
                              Quadruple
                            </option>
                          </select>
                        </div>
                        {displayrooms > 0 ?
                          <div className="label-input-wrap">
                            <label htmlFor="noOfrooms">No. of Rooms:</label>
                            <select
                              id="noOfrooms"
                              onChange={handleNoOfrooms}
                              disabled={allDisabled}
                              value={noOfrooms}
                              required>
                              <option value="">Select no. of rooms</option>
                              {Array.from(Array(5).keys(), (val) => val + 1).map((val) => (
                                <option key={val} value={val}>
                                  {val}
                                </option>
                              ))}
                            </select>
                          </div>
                          : ''}
                        <div className="row">
                          <div className="label-input-wrap col-6">
                            <label htmlFor="noOfAccompanyingPeople">
                              Adults ( Above 9 years):
                            </label>
                            <select
                              id="noOfAccompanyingPeople"
                              onChange={handlePeople}
                              disabled={allDisabled}
                              value={People}
                              required>
                              <option value="">Select no. people</option>
                              {Array.from(Array(21).keys(), (val) => val + 1).map((val) => (
                                <option key={val} value={val}>
                                  {val}
                                </option>
                              ))}
                            </select>
                          </div>
                          {displaychild > 0 ?
                            <div className="label-input-wrap col-6">
                              <label htmlFor="childPeople">
                                Children ( Below 9 years):
                              </label>
                              <select
                                id="childPeople"
                                disabled={allDisabled}
                                onChange={handleChild}
                                required={displaychild > 0 ? true : false}
                                value={noofchild}
                              >
                                <option value="">Select no. child</option>
                                {Array.from(Array(21).keys(), (val) => val + 0).map((val) => (
                                  <option key={val} value={val}>
                                    {val}
                                  </option>
                                ))}
                              </select>
                            </div>
                            : ''}
                        </div>
                        <div className="row">
                          <div >
                            <input type="radio" value="fullpay" onChange={handelPartPay} checked={part_pay === 'fullpay'} name="handeFullPay" /> Full Payment&nbsp;&nbsp;&nbsp;&nbsp;
                            <input type="radio" value="partpay" onChange={handelPartPay} checked={part_pay === 'partpay'} name="handelPartPay" /> Part Payment
                          </div>
                        </div>
                        {part_pay === 'fullpay' ?
                          '' :
                          <div className="label-input-wrap mt-2">
                            <label htmlFor="partPayment">
                              Enter Amount
                            </label>
                            <input
                              id="partPayment"
                              readOnly={allDisabled}
                              {...register("partPayment")}
                              type="text"
                              placeholder="Enter Amount"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}

                              onKeyUp={handleMinPriceKeyUp}

                              required={part_pay === 'fullpay' ? false : true}
                            />

                            <small className="error">{errorMinPrice}</small>

                          </div>}
                        <div className="label-input-wrap">
                          <label>
                            Total Price: ₹{totalPrice > 0 ? totalPrice : '0'}
                          </label>
                        </div>
                      </div>
                    </>}
                  <div className="col-12">
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                      ref={recaptchaRef}
                      size="invisible"
                    />
                  </div>
                  {allDisabled == true && leftamount == 0 ? '' :
                    <div className="pop-form-btn">
                      <button className="box-hover" disabled={disabledBtn} >
                        Submit
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 448 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                        </svg>
                      </button>
                    </div>
                  }
                  <p className="mb-0 mt-3">
                    <strong>Note:-</strong> <br></br>
                    - Please register your interest as seats are limited.<br></br>
                    - Yatra cost per day Rs.1000 and  Room cost per day Rs.2200.
                  </p>

                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <GoToTop />
    </>
  );
};

export default Yatras;
