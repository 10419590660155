import PageHeader from "../components/common/PageHeader";
import GoToTop from "../helpers/GoToTop";
import MetaData from "../helpers/MetaData";
import { Modal } from "react-bootstrap";
import { useCallback, useEffect, useRef, useState } from "react";
import ApiService from "../api/ApiService";


const GovindaCafe = () => {
    const scrollToElement = () => {
        const element = document.getElementById('scrollToAllPages');
        // console.log("elements",element);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
        }
    };
    const [meta, setMeta] = useState(null)
    const fetchMeta = async (pagename) => {
        try {
            let res = await ApiService.fetchMetaData(pagename)
            setMeta(res)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {

        scrollToElement();
    }, [])

    useEffect(() => {
        fetchMeta('govinda_cafe')
    }, [])


    return (
        <>
            <MetaData title="Govinda's Cafe" />
            <PageHeader
                title="Govinda's Cafe"
                imgSrc="/images/page-header/20.webp"
            />
            <section className="govinda-heading-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="heading">
                                <h2 className="head-1">
                                    Govinda's Cafe
                                    Enjoy the taste of Purity
                                </h2>
                                <p>
                                    The Govinda’s Cafe at ISKCON Attapur offers very delicious Krishna prasad for all the visiting devotees. You can relish the taste of purity in Satvik Indian, Chinese, other cuisines.
                                </p>
                                <h3>Discover what we Serve</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="goswamis-sec">
                <div className="container">
                    <div className="goswamis-card-wrap">
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-6 mb-5">
                                <div className="goswami-card" id="scrollToAllPages">
                                    <div className="book-top">
                                        <figure> {/* onClick={() => setGoswami1(true)} */}
                                            <img src="/images/govinda_cafe/samosa.png" alt="" />
                                            {/* <button className="custom-btn-cls box-hover">Read More</button> */}
                                        </figure>
                                        <h5>Indian</h5>
                                        <ul>
                                            <li>Samosa</li>
                                            <li>Paneer Tikki</li>
                                            <li>Paneer Chilli</li>
                                            <li>Paneer Crispy</li>
                                            <li>Fruit Salad</li>

                                        </ul>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6  mb-5">
                                <div className="goswami-card">
                                    <div className="book-top">
                                        <figure > {/* onClick={() => setGoswami2(true)} */}
                                            <img src="/images/govinda_cafe/pasta.jpeg" alt="" />
                                            {/* <button className="custom-btn-cls box-hover">Read More</button> */}
                                        </figure>
                                        <h5>Chinese and Continental</h5>
                                        <ul>
                                            <li>Manchurian​</li>
                                            <li>Pasta</li>
                                            <li>Hakka Noodles</li>
                                            <li>Chow mein </li>
                                            <li>Fried Rice</li>
                                            <li>English Cutlet</li>

                                        </ul>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-5">
                                <div className="goswami-card">
                                    <div className="book-top">
                                        <figure > {/*onClick={() => setGoswami3(true)} */}
                                            <img src="/images/govinda_cafe/mango-lassi-featured.jpg" alt="" />
                                            {/* <button className="custom-btn-cls box-hover">Read More</button> */}
                                        </figure>
                                        <h5>Beverages</h5>
                                        <ul>
                                            <li>Thandai​</li>
                                            <li>Mango Lassi</li>
                                            <li>Strawberry Lassi</li>
                                            <li>Apple Juice</li>
                                            <li>Watermelon Juice</li>
                                            <li>Lichi Soda Juice</li>
                                            <li>Herbal Tea</li>
                                        </ul>
                                    </div>

                                </div>
                            </div>



                        </div>
                    </div>

                </div>
            </section>


            <GoToTop />
        </>
    );
};

export default GovindaCafe;
