import React, { useCallback, useEffect, useRef, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import ApiService from "../../api/ApiService";
import { Link, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Modal } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useForm } from "react-hook-form";
import notification from "../../helpers/notification";
import ReCAPTCHA from "react-google-recaptcha";

const OnDemandCourses = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const ref = useRef();
  const recaptchaRef = useRef();
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [popupHeight, setPopupHeight] = useState(0);

  const formRef = useCallback((node) => {
    if (show) {
      setPopupHeight(node?.offsetHeight);
    }
    const paraHeight = document.querySelector(".popup-text-wrap");
    paraHeight.style.height = popupHeight + "px";
  });

  const fetchCourses = async () => {
    try {
      const res = await ApiService.fetchCourses();
      setCourses(res);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCourseClick = (course) => {
    setSelectedCourse(course);
    setShow(true);
  };

  const handleHideModal = () => {
    setShow(false);
  };

  const handleFormSubmit = async (data) => {
    try {
      const token = await recaptchaRef.current.executeAsync();
      // console.log(token)
      data.token = token;

      await ApiService.createEnquiry({
        service: selectedCourse?._id,
        type: "course",
        ...data,
      });
      notification("success", "Hare Krishna! Your form has been accepted.");
      reset();
      setShow(false);
      recaptchaRef.current.reset();
    } catch (error) {
      console.log(error);
      notification("error", error.response.data.message);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  return (
    <>
      <section className="ondemand-courses">
        <div className="container">
          <div className="heading">
            <h2 className="head-1">ON-DEMAND COURSES</h2>
          </div>
          <div className="card-wrap mt-5">
            <Splide
              className="testimonial-slider"
              ref={ref}
              options={{
                perPage: 4,
                // type: 'loop',
                perMove: 1,
                gap: 20,
                arrows: false,
                pagination: false,
                autoplay: true,
                speed: 1000,
                interval: 8000,
                breakpoints: {
                  576: {
                    perPage: 1,
                    gap: 20,
                  },
                  768: {
                    perPage: 2,
                    trimSpace: true,
                  },
                  992: {
                    trimSpace: true,
                    perPage: 2,
                    gap: 20,
                  },
                  1024: {
                    trimSpace: true,
                    perPage: 3,
                    gap: 20,
                  },
                  1200: {
                    trimSpace: false,
                    perPage: 3,
                  },
                  1400: {
                    trimSpace: false,
                    perPage: 4,
                  },
                },
              }}
            >
              {courses.length === 0 ? (
                <>
                  <SplideSlide>
                    <Skeleton height={375} />
                  </SplideSlide>
                  <SplideSlide>
                    <Skeleton height={375} />
                  </SplideSlide>
                  <SplideSlide>
                    <Skeleton height={375} />
                  </SplideSlide>
                  <SplideSlide>
                    <Skeleton height={375} />
                  </SplideSlide>
                </>
              ) : (
                <>
                  {courses.map((course, i) => (
                    <SplideSlide key={i}>
                      <div
                        onClick={() =>
                          course?.redirect
                            ? navigate(`${course?.link}`)
                            : handleCourseClick(course)
                        }
                      >
                        <div className="card-bg">
                          <div className="card-overlay">
                            <div className="card-text">
                              <p style={{color:"#c4c5c6"}}>
                                {course?.title.split("|").length === 1
                                  ? course?.title.split("|")[0]
                                  : course?.title.split("|")[0] +
                                    course?.title.split("|")[1]}
                              </p>
                              <h6>{course?.tag}</h6>
                              {course?.redirect ? (
                                <Link
                                  className="card-btn custom-btn-cls box-hover"
                                  to={`${course?.link}`}
                                >
                                  {course?.buttonText}
                                </Link>
                              ) : (
                                <button
                                  className="card-btn custom-btn-cls box-hover"
                                  onClick={() => handleCourseClick(course)}
                                >
                                  {course?.buttonText}
                                </button>
                              )}
                            </div>
                          </div>
                          <figure>
                            <img
                              src={`${process.env.REACT_APP_S3_URL}/uploads/course/${course?.image}`}
                              alt=""
                            />
                          </figure>
                        </div>
                      </div>
                    </SplideSlide>
                  ))}
                </>
              )}
            </Splide>
          </div>
        </div>
        <div className="arrow-wrap">
          <button
            className="slider-btn prev box-hover"
            onClick={() => ref.current.splide.go("-1")}
          >
            <FiChevronLeft />
          </button>
          <button
            className="slider-btn next box-hover"
            onClick={() => ref.current.splide.go("+1")}
          >
            <FiChevronRight />
          </button>
        </div>
        <div className="course-open d-flex justify-content-center">
          <Link className="custom-btn-cls box-hover" to="/courses">
            VIEW COURSES
          </Link>
        </div>
      </section>

      <Modal centered show={show} onHide={handleHideModal}>
        <div className="modal-wrap">
          <button className="btn close-svg" onClick={handleHideModal}>
            <svg
              className="close-svg"
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 16 16"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"
              ></path>
            </svg>
          </button>
          <div className="row">
            <div className="col-md-5 d-sm-block d-none">
              <figure>
                <img
                  src={`${process.env.REACT_APP_S3_URL}/uploads/course/${selectedCourse?.image}`}
                />
              </figure>
            </div>
            <div className="col-md-7">
              <div className="pop-up-form">
                <div className="pop-up-top mb-0 border-0">
                  <div className="heading">
                    <h2 className="head-2">
                      {selectedCourse?.title.split("|")[0]}
                    </h2>
                    <h2 className="head-1">
                      {selectedCourse?.title.split("|")[1]}
                    </h2>
                  </div>
                  <figure className="hare-krishna-logo-fig">
                    <img src="/images/modal/hare-krishna-logo.svg" alt="" />
                  </figure>
                </div>
                <div className="modal-tab-wrap courses-mod">
                  <Tabs
                    defaultActiveKey="modalForm"
                    id="uncontrolled-tab-example"
                  >
                    <Tab eventKey="modalForm" title="REGISTER">
                      <form
                        className="mt-3 mt-sm-0 pt-4"
                        onSubmit={handleSubmit(handleFormSubmit)}
                        ref={formRef}
                      >
                        <div className="row">
                          {selectedCourse?.inputAddOns?.includes(
                            "Name of Child"
                          ) && (
                            <div className="col-sm-6">
                              <div className="label-input-wrap">
                                <label htmlFor="nameOfChild">
                                  Name of Child
                                </label>
                                <input
                                  type="text"
                                  id="nameOfChild"
                                  {...register("nameOfChild", {
                                    required: true,
                                  })}
                                  onKeyPress={(event) => {
                                    if (/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                                {errors.nameOfChild?.type === "required" && (
                                  <small className="error">
                                    This is required
                                  </small>
                                )}
                              </div>
                            </div>
                          )}
                          {selectedCourse?.inputAddOns?.includes(
                            "Age of Child"
                          ) && (
                            <div className="col-sm-6">
                              <div className="label-input-wrap">
                                <label htmlFor="ageOfChild">Age of Child</label>
                                <input
                                  type="text"
                                  id="ageOfChild"
                                  {...register("ageOfChild", {
                                    required: true,
                                  })}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                                {errors.ageOfChild?.type === "required" && (
                                  <small className="error">
                                    This is required
                                  </small>
                                )}
                              </div>
                            </div>
                          )}
                          {selectedCourse?.inputAddOns?.includes(
                            "Name of Parent"
                          ) && (
                            <div className="col-sm-6">
                              <div className="label-input-wrap">
                                <label htmlFor="nameOfParent">
                                  Name Of Parent
                                </label>
                                <input
                                  type="text"
                                  id="nameOfParent"
                                  {...register("nameOfParent", {
                                    required: true,
                                  })}
                                  onKeyPress={(event) => {
                                    if (/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                                {errors.nameOfParent?.type === "required" && (
                                  <small className="error">
                                    This is required
                                  </small>
                                )}
                              </div>
                            </div>
                          )}
                          {selectedCourse?.inputAddOns?.includes("Name") && (
                            <div className="col-sm-6">
                              <div className="label-input-wrap">
                                <label htmlFor="name">Name</label>
                                <input
                                  type="text"
                                  id="name"
                                  {...register("name", { required: true })}
                                  onKeyPress={(event) => {
                                    if (/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                                {errors.name?.type === "required" && (
                                  <small className="error">
                                    This is required
                                  </small>
                                )}
                              </div>
                            </div>
                          )}
                          {selectedCourse?.inputAddOns?.includes("Email") && (
                            <div className="col-sm-6">
                              <div className="label-input-wrap">
                                <label htmlFor="email">Email</label>
                                <input
                                  type="text"
                                  id="email"
                                  {...register("email", { required: true })}
                                />
                                {errors.email?.type === "required" && (
                                  <small className="error">
                                    This is required
                                  </small>
                                )}
                              </div>
                            </div>
                          )}
                          {selectedCourse?.inputAddOns?.includes("Phone") && (
                            <div className="col-sm-6">
                              <div className="label-input-wrap">
                                <label htmlFor="phone">Phone</label>
                                <input
                                  type="text"
                                  id="phone"
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  {...register("phone", {
                                    required: true,
                                    pattern: {
                                      value: /^[6-9]\d*$/,
                                      message: "Please provide valid phone number",
                                    },
                                    minLength: {
                                      value: 10,
                                      message: "Phone must be minimum 10 digit",
                                    },
                                    maxLength: {
                                        value: 10,
                                        message: "Phone must be maximum 10 digit",
                                      },
                                  })}
                                />
                                {errors.phone?.type === "required" && (
                                  <small className="error">
                                    This is required
                                  </small>
                                )}
                                {errors.phone && (
                                  <small className="error">
                                    {errors.phone.message}
                                  </small>
                                )}
                              </div>
                            </div>
                          )}
                          {selectedCourse?.inputAddOns?.includes(
                            "Location"
                          ) && (
                            <div className="col-sm-6">
                              <div className="label-input-wrap">
                                <label htmlFor="location">Location</label>
                                {/* <select id="location" {...register("location",{required:true})}>
                                                            <option value="">Select location</option>
                                                            <option value="Bhiwandi">Bhiwandi</option>
                                                            <option value="Thane">Thane</option>
                                                            <option  value="Mumbai">Mumbai</option>
                                                            </select> */}
                                <input
                                  type="text"
                                  id="location"
                                  {...register("location", { required: true })}
                                />
                                {errors.location?.type === "required" && (
                                  <small className="error">
                                    This is required
                                  </small>
                                )}
                              </div>
                            </div>
                          )}
                          {selectedCourse?.inputAddOns?.includes("Address") && (
                            <div className="col-sm-6">
                              <div className="label-input-wrap">
                                <label htmlFor="address">Address</label>
                                <input
                                  type="text"
                                  id="address"
                                  {...register("address", { required: true })}
                                />
                                {errors.address?.type === "required" && (
                                  <small className="error">
                                    This is required
                                  </small>
                                )}
                              </div>
                            </div>
                          )}
                          {selectedCourse?.inputAddOns?.includes(
                            "No. of singers"
                          ) && (
                            <div className="col-sm-6">
                              <div className="label-input-wrap">
                                <label htmlFor="noOfSingers">
                                  No of singers
                                </label>
                                <input
                                  type="text"
                                  id="noOfSingers"
                                  {...register("noOfSingers", {
                                    required: true,
                                  })}
                                />
                                {errors.noOfSingers?.type === "required" && (
                                  <small className="error">
                                    This is required
                                  </small>
                                )}
                              </div>
                            </div>
                          )}
                          {selectedCourse?.inputAddOns?.includes(
                            "Size of gathering"
                          ) && (
                            <div className="col-sm-6">
                              <div className="label-input-wrap">
                                <label htmlFor="sizeOfGathering">
                                  Size of gathering
                                </label>
                                <input
                                  type="text"
                                  id="sizeOfGathering"
                                  {...register("sizeOfGathering", {
                                    required: true,
                                  })}
                                />
                                {errors.sizeOfGathering?.type ===
                                  "required" && (
                                  <small className="error">
                                    This is required
                                  </small>
                                )}
                              </div>
                            </div>
                          )}

                          {selectedCourse?.inputAddOns?.includes(
                            "Special Note"
                          ) && (
                            <div className="col-12">
                              <div className="label-input-wrap">
                                {/* <label htmlFor="courseNote"> What inspired you to register for the "{selectedCourse?.title.split("|")[0]} {selectedCourse?.title.split("|")[1]}"?</label> */}
                                <label htmlFor="specialNote">
                                  Special Note?
                                </label>
                                <textarea
                                  type="text"
                                  id="specialNote"
                                  {...register("specialNote")}
                                />
                                {/* {errors.specialNote?.type === 'required' && <small className="error">This is required</small> } */}
                              </div>
                            </div>
                          )}
                          {selectedCourse?.inputAddOns?.includes(
                            "Course Note"
                          ) && (
                            <div className="col-12">
                              <div className="label-input-wrap">
                                {/* <label htmlFor="courseNote"> What inspired you to register for the "{selectedCourse?.title.split("|")[0]} {selectedCourse?.title.split("|")[1]}"?</label> */}
                                <label htmlFor="courseNote">
                                  {" "}
                                  What are you seeking to gain from this course?
                                </label>
                                <textarea
                                  type="text"
                                  id="courseNote"
                                  {...register("courseNote", {
                                    required: true,
                                  })}
                                />
                                {errors.courseNote?.type === "required" && (
                                  <small className="error">
                                    This is required
                                  </small>
                                )}
                              </div>
                            </div>
                          )}
                          {selectedCourse?.inputAddOns?.includes(
                            "Session Note"
                          ) && (
                            <div className="col-12">
                              <div className="label-input-wrap">
                                {/* <label htmlFor="courseNote"> What inspired you to register for the "{selectedCourse?.title.split("|")[0]} {selectedCourse?.title.split("|")[1]}"?</label> */}
                                <label htmlFor="sessionNote">
                                  What are you seeking to gain from this
                                  session?
                                </label>
                                <textarea
                                  type="text"
                                  id="sessionNote"
                                  {...register("sessionNote", {
                                    required: true,
                                  })}
                                />
                                {errors.sessionNote?.type === "required" && (
                                  <small className="error">
                                    This is required
                                  </small>
                                )}
                              </div>
                            </div>
                          )}
                          <div className="col-12">
                            <ReCAPTCHA
                              sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                              ref={recaptchaRef}
                              size="invisible"
                            />
                          </div>
                        </div>

                        <div className="pop-form-btn">
                          <button className="box-hover">
                            Submit
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 448 512"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                            </svg>
                          </button>
                        </div>
                      </form>
                    </Tab>
                    <Tab eventKey="modalDesc" title="VIEW COURSE HIGHLIGHTS">
                      <div className="popup-text-wrap">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: selectedCourse?.description,
                          }}
                          style={{ color: "#7c848d" }}
                          className=""
                        ></div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default OnDemandCourses;
