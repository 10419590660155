import fileDownload from 'js-file-download'
import React, { useContext, useLayoutEffect } from 'react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ApiService from '../api/ApiService'
import AppContext from '../context/AppContext'

const Download80G = () => {

    const {setShowHeaderFooter} = useContext(AppContext)
    const {id} = useParams()
    const navigate = useNavigate()

    const download80G = async () => {
      try {
        const res = await ApiService.download80g(`${id}.pdf`)
        fileDownload(res, '80G-certificate.pdf')
      } catch (err) {
        if(err.response.status === 404) {
          return window.location = `${process.env.REACT_APP_PROJECT_URL}/update-details?update-pan=${id}&donation-type=campaign`
          return navigate(`/update-details?update-pan=${id}&donation-type=campaign`, {
            replace: true,
          })
        }
        if(err.response.status === 500) {
          return navigate(`/`,{
            replace: true
          })
        }
      } 
    }

    useEffect(() => {
        setShowHeaderFooter(false)
        download80G()
        // let timer = setTimeout(() => {
        //   navigate('/',{
        //     replace: true
        //   })
        // },200)

        // return () => {
        //   clearTimeout(timer)
        //   setShowHeaderFooter(true)
        //   window.location.reload()
        // }
    },[])


  return (
    <div className='downloading-wrap'>
      <h2>Downloading...</h2>
    </div>
  )
}

export default Download80G