import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'react-loading-skeleton/dist/skeleton.css'
import { AppContextProvider } from './context/AppContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AppContextProvider>
        <App />
    </AppContextProvider>
);


