import GoToTop from "../helpers/GoToTop";
import MetaData from "../helpers/MetaData";
import { Link } from "react-router-dom";
const RecurringPolicy = () => {
    return (
        <>
            <MetaData title="Recurring Payments Terms" />
            <section className="refund-policy-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <div className="heading">
                                <h2 className="head-1">Recurring Payments Terms</h2>

                                <p>ISKCON may offer donors the option to make donations on a recurring basis ("Recurring Donations") to support its various programs, services, and initiatives. Recurring Donations help ensure a steady stream of support for ISKCON's ongoing mission and activities.
                                    ISKCON reserves the right to modify or discontinue the Recurring Donations program at any time without prior notice to donors.
                                    Donors may choose to participate in the Recurring Donations program but are not obligated to do so. By opting for Recurring Donations, donors agree to be bound by these terms in addition to any other applicable terms and conditions related to their donations.
                                </p>

                                <h2 className="head-1" style={{fontSize:'24px'}}>Eligible Payment Methods</h2>

<p>Recurring Donations can only be set up using a current, valid, and acceptable payment method registered with the donor’s account. This method is hereinafter referred to as the "Payment Method."
ISKCON reserves the right to determine which Payment Methods are eligible for Recurring Donations and may extend the program to selected Payment Methods or products offered by specific banks or payment providers.
Donors are solely responsible for providing accurate information about their chosen Payment Method, including card details (credit/debit).
</p>


<h2 className="head-1" style={{fontSize:'24px'}}>Enabling Recurring Donations</h2>

<p>ISKCON may offer donors the ability to enable Recurring Donations when initiating or renewing their donations.
If a donor selects the Recurring Donations option, their donation will automatically renew for the chosen donation period(s), and the donor will be charged automatically through their selected Payment Method on the last day of each donation period.
Before Recurring Donations are activated, a small verification charge may be made to the donor's Payment Method for authentication purposes. Once verification is successful, the donor will be registered for Recurring Donations, and the verification charge will be refunded.
</p>


<h2 className="head-1" style={{fontSize:'24px'}}>Donation Processing</h2>

<p>Donations will be processed upon successful charging of the donor's Payment Method.
Once Recurring Donations are enabled, the donor authorises ISKCON or its designated third-party payment processor to charge the donation amount for subsequent donation periods unless the donor cancels or disables Recurring Donations.
.
</p>

<h2 className="head-1" style={{fontSize:'24px'}}>Disabling or Cancelling Recurring Donations</h2>

<p>Donors may cancel or disable Recurring Donations for their ISKCON donations by adjusting their account settings or contacting ISKCON's donor support. If a donor cancels or disables Recurring Donations, their current donation period will continue for the remainder of that period, but the donation will not renew automatically.
If a Recurring Donation payment is declined for any reason, ISKCON may attempt to process the payment using any alternative Payment Methods on file for the donor. If all Payment Methods are declined, ISKCON may cancel the donor's Recurring Donations unless a new Payment Method is provided.
</p>



<h2 className="head-1" style={{fontSize:'24px'}}>Notifications and Communication</h2>

<p>Donors authorise ISKCON to communicate with them via email/WhatsApp/SMS regarding their donations and Recurring Donations. ISKCON may also communicate with donors through its affiliates or partners involved in the provision of programs or services supported by the donations.
</p>


                                {/* <div className="terms-and-condition-btn mt-5">
                                    <Link className="custom-btn-cls box-hover" to="/privacy-policy">Privacy Policy</Link>
                                    <Link className="custom-btn-cls box-hover" to="/refund-policy">Refund policy</Link>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <GoToTop />
        </>
    );
}

export default RecurringPolicy;
