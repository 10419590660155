import { Helmet } from 'react-helmet-async'

const MetaData = ({title, meta}) => {
  return (
    <Helmet prioritizeSeoTags>
      {
        meta
        ?
        <>
          <title>{ meta.title }</title>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="description" content={ meta.description } />
          <meta http-equiv="X-UA-Compatible" content="ie=edge" />
          <link rel="canonical" href="https://www.iskconattapur.com" />
          <link rel="alternate" href="https://www.iskconattapur.com" hreflang="en-in" />
          <meta property="og:url" content="https://www.iskconattapur.com" />
          <meta property="og:title" content={ meta.title } />
          {
            meta.overWriteImage
            ?
            <meta property="og:image" content={ `${meta.ogImage}` } />
            :
            <meta property="og:image" content={ `${process.env.REACT_APP_S3_URL}/uploads/seo/${meta.ogImage}` } />
          }
          <meta property="og:site_name" content={ process.env.REACT_APP_PROJECT_NAME } />
          <meta property="og:description" content={ meta.description } />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content={ meta.title } />
          <meta property="og:title" content={ meta.title } />
          <meta property="og:type" content="article" />
          <meta name="twitter:card" content="summary" />
          {/* <meta name="twitter:site" content="@ideamagix" /> */}
          <meta name="twitter:title" content={ meta.title } />
          <meta name="twitter:description" content={ meta.description } />
        </>
        :
        <title>{title}</title>

      }
    </Helmet>
  )
}

export default MetaData