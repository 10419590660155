import PageHeader from "../components/common/PageHeader"
import GoToTop from "../helpers/GoToTop";
import MetaData from "../helpers/MetaData";
import { useState } from "react";

const SecretOfSuccess = () => {
    return ( 
        <>
          <MetaData title="Secret Of Success" />
            <PageHeader title="SECRET OF SUCCESS" imgSrc="/images/page-header/18.webp" />
            <section className="secret-head-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="heading">
                                <h2 className="head-1">
                                Learn practical tips from our Ancient Scriptures on how to be Happy and Successful in Life.
                                </h2>
                                <h2 className="head-2">
                                Join our 2 days Secrets of Success seminar.
                                </h2>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </section>
            <section className="secret-head1-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <div className="heading">
                                <h2 className="head-1">
                                3 SESSIONS
                                </h2>
                                <ul className="list-unstyled">
                                    <li className='bullet'>
                                    Session 1 : 09 July (5 pm - 8 pm) : Defining Success, Laws of Karma, Real Problems of Life
                                    </li>
                                    <li className='bullet'>
                                    Session 2 : 10 July (5 pm - 8 pm) : Vedas, Definition of GOD and ABCD for Success of Life
                                    </li>  
                                </ul>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </section>
            <section className="secret-head2-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <div className="heading">
                                <h2 className="head-1">
                                COURSE CONTENT
                                </h2>
                                <ul className="list-unstyled">
                                    <li className='bullet'>
                                    Definition of Success and what is the real success of life.
                                    </li>
                                    <li className='bullet'>
                                    Knowing your real identity
                                    </li>  
                                    <li className='bullet'>
                                    Mind & Intelligence mechanism
                                    </li>
                                    <li className='bullet'>
                                    Law of Karma and how it affects our success
                                    </li>  
                                    <li className='bullet'>
                                    Vedas : The Manual of Life- How it guides us to get success
                                    </li>
                                    <li className='bullet'>
                                    Real Problems & Relative Problems
                                    </li>  
                                    <li className='bullet'>
                                    The Two worlds...
                                    </li>  
                                    <li className='bullet'>
                                    Do I need God to be "Successful"?
                                    </li>
                                    <li className='bullet'>
                                    The most powerful meditation technique
                                    </li>  
                                    <li className='bullet'>
                                    How to become stress-free?
                                    </li>
                                    <li className='bullet'>
                                    True path to Success of Life
                                    </li>  
                                    <li className='bullet'>
                                    The ABCD in Secret of Success
                                    </li>  
                                </ul>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </section>
            <section className="secret-head3-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <div className="heading">
                                <h2 className="head-1">
                                Free Additional Sessions
                                </h2>
                                <ul className="list-unstyled">
                                    <li className='bullet'>
                                    1 on 1 session to clear your doubts (30-40 minutes)
                                    </li>
                                    <li className='bullet'>
                                    Access to daily online Bhagavad Gita Sessions
                                    </li>  
                                    <li className='bullet'>
                                    Access to systematic understanding of Bhagavad Gita Sessions held every sunday.
                                    </li>  
                                </ul>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </section>
            <section className="success-registration-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <div className="success-registration-wrap">
                                <button className="custom-btn-cls box-hover">REGISTER FOR FREE</button>
                                    <p className="note-para"><u>Note</u> - Zoom conference details would be sent to you before the session begins</p>
                                    <div className="success-contact">
                                        <p>For any Query,<a className="redirect-link" href="https://wa.me/918840054776?text=Hare%20Krishna" target="_blank"> whatsapp on +91-9321961341 </a> or </p>
                                        <p>e-mail us at: <a href = "mailto: iskconbhiwandi@gmail.com">iskconbhiwandi@gmail.com</a></p>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <GoToTop />
        </>
     );
}
 
export default SecretOfSuccess;