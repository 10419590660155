import { useEffect, useState } from 'react'; 
import { BsArrowDownCircleFill } from 'react-icons/bs';
import Fancybox from "../../helpers/Fancybox"
import  { BiPlus }  from 'react-icons/bi'
import { saveAs } from 'file-saver'

const WallpaperCard = ({ imgSrc, title, thumbnailImgUrl }) => {

    return ( 
        <Fancybox >  
            <a className='text-decoration-none' data-fancybox="gallery" href={thumbnailImgUrl} data-thumb={thumbnailImgUrl} >
                <div className="wallpaper-card">
                    <figure>
                        <img src={thumbnailImgUrl} alt={title} />
                        <button onClick={() =>  saveAs(imgSrc)} className="custom-btn-cls box-hover">DOWNLOAD</button>
                    </figure>
                    <span className="hover-plus">
                        <BiPlus/>
                    </span>
                </div>
            </a> 
        </Fancybox >   
     );
}
 
export default WallpaperCard;