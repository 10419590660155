import React from 'react'
import {HiOutlineArrowNarrowLeft} from 'react-icons/hi'

function PageNotFound() {

  return (
    <section className='pageNotFound'>
        <div className='col-sm-10 mx-auto'>
            <div className='pageNotFound-wrapper'>
              <div className="row">
                <div className="col-lg-6 d-flex justify-content-center align-items-center">
                  <div className="round-404 ">
                    <h1>404</h1>
                    <h2>Page Not Found</h2>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="content-404">
                    <p className='textline1-404'>The link you clicked isn't working.</p>
                    <p className='textline2-404'>But stay focused and devoted... </p>
                    <p className="textline3-404">You'll find what you're looking for.</p>
                    <div className='d-flex justify-content-center'>
                      <a href='/' className='custom-btn-cls box-hover'><HiOutlineArrowNarrowLeft/>Take Me To Home Page</a>
                      <a href='/donation-form' className='custom-btn-cls box-hover'>Donate</a>
                    </div>
                  </div>
                </div>
              </div>
                
            </div>
        </div>
    </section>
  )
}

export default PageNotFound