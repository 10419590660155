import React, { useEffect, useState } from "react";
import GoToTop from "../helpers/GoToTop";
import { VscClose } from "react-icons/vsc";
import { Link, useLocation } from "react-router-dom";
import ApiService from "../api/ApiService";

const PurchaseFailed = () => {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const [purchaseDetails, setPurchaseDetails] = useState(null);

  const fetchPurchaseDetails = async () => {
    try {
      const res = await ApiService.fetchPurchaseDetails(id);
    //   console.log(res);
      setPurchaseDetails(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPurchaseDetails();
  }, []);

  return (
    <>
      <section className="thank-you">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 mx-auto">
                    <div className="thank-you-wrapper">
                    <div className="heading">
                        <span className="icon failed">
                        <VscClose />
                        </span>
                        <h2 className="head-1 text-danger">Payment Unsuccessful</h2>
                        <p>Your payment was unsuccessful. Please try again.</p>
                    </div>
                    <div className="d-flex justify-content-center">
                        <Link
                        to={`/shop-checkout?shopId=${id}`}
                        className="custom-btn-cls box-hover"
                        >
                        Retry Payment
                        </Link>
                    </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
    </>
  );
};

export default PurchaseFailed;
