import { useEffect } from "react";
import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import ApiService from "../api/ApiService";
import CampaignSlider from "../components/campaign/CampaignSlider";
import MetaData from "../helpers/MetaData";
import notification from "../helpers/notification";
const CertificateView = () => {
    
    let params = useParams();
    const [searchParams] = useSearchParams();

    const [details, setDetails] = useState(null)

    const fetchDetails = async () => {
        try {
            const res = await ApiService.fetchPaymentDetails(params.id, searchParams.get('type'))
            setDetails(res)
        }catch (err) {
            console.log(err)
            notification('error', 'Something went wrong!')
        }
    }
    
    const meta = {
        title: `${details?.name}'s Certificate - ISKCON of Attapur`,
        description: `Thank You for your generous donation. As you have so lovingly given, so may the grace of Krishna flow in to your life and may you, for all times, be in His refuge.`,
        ogImage: `${process.env.REACT_APP_S3_URL}/uploads/certificate/${params.id}.jpg`,
        overWriteImage: true
    }

    useEffect(() => {
        fetchDetails();
    },[])

    return ( 
        <>
        <MetaData title={`${details?.name}'s Certificate - ISKCON of Attapur`} meta={meta} />
        <section className="certificate-view-sec">
            <div className="container">
                <figure className="mb-0 vaishnavism-banner">
                    <img src={`${process.env.REACT_APP_S3_URL}/uploads/certificate/${params.id}.jpg`} alt="" />
                </figure>
            </div>
        </section>
        </>
     );
}
 
export default CertificateView;