import MetaData from '../helpers/MetaData'
import PageHeader from '../components/common/PageHeader'
import GoToTop from '../helpers/GoToTop'
import { Link } from 'react-router-dom'
import { FaFacebookF,FaTwitter,FaLinkedinIn,FaDownload} from 'react-icons/fa'

const PublicThankyou = () => {
    
  return (
   <>
    <MetaData title="Thank You - ISKCON of Attapur" />
    <PageHeader  title="Thank You" imgSrc="/images/page-header/25.jpg" />
        <section className="thank-you-container">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                <div className="col-10 mx-auto">
                    <div className="thank-you-text text-center">
                    <div className="heading text-center">
                        <h2 className="head-1 text-center">Thank You!</h2>
                        <p className="mt-4">Dear Krishna Bhakt,</p>
                        <p className='mt-0'>
                            Thank You for your generous donation. As you have so lovingly given, so may the grace of Krishna flow in to your life and may you, for all times, be in His refuge. 
                        </p>
                        <p className='mt-0'>
                            As a token of our appreciation we will be happy to offer collective prayers in your name on your special occasions like your Birthday, Marriage Anniversary, Parent’s and Children’s birthdays or any other. Kindly share details in the form here. 
                        </p>
                        <p className='mt-0'>
                            Hare Krishna.
                        </p>
                    </div>
                    <Link to="/join-family" className='d-inline-block text-center box-hover custom-btn thanks mt-4'>JOIN THE FAMILY OF LORD KRISHNA</Link>
                    </div>
                </div>
                </div>
            </div>
        </section>
     
        <GoToTop/>
   </>
  )
}

export default PublicThankyou