import { useEffect } from 'react'
import { useLocation } from 'react-router-dom' 

const GoToTop = ({top = 0}) => {

  const routePath = useLocation()

  const onTop = (top) => {
    setTimeout(() => {
      window.scrollTo(0,top)
    },400)
  }
  
  useEffect(() => {

    onTop(top);

  },[routePath, top])

  return null;
}

export default GoToTop