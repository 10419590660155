import React from 'react'
import Fancybox from '../../helpers/Fancybox';

const IskconStoryVideo = ({imgSrc, videoLink, smallText, bigText}) => {
  return (
    <Fancybox>
        <a data-fancybox="video" href={videoLink}>
            <section className="about-video" style={{backgroundImage: `url(${imgSrc})`}}>
                <div className="container">
                    <div className="title">
                        <h2>{smallText}<span className="d-block">{bigText}</span></h2>
                    </div>
                </div>
                <figure className="play-icon">
                    <img src="/images/about-us/play.png" alt="" />
                </figure>
            </section>
        </a>
    </Fancybox>
  )
}

export default IskconStoryVideo