import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react'
import FestivalCard from './FestivalCard';
import ApiService from '../../api/ApiService';
import Skeleton from 'react-loading-skeleton';


const FestivalsCardWrap = () => {
    const [festivals,setFestivals]=useState([])
    const fetchFestivals=async()=>{
       try{
         const res= await ApiService.fetchFestivals()
        //  console.log(res)
         setFestivals(res)
       }catch(error){
         console.log(error)
       }
    }

    useEffect(()=>{
        fetchFestivals()
    },[])

  return (
    <section className="content">
            <div className="container">
                {
                    !(festivals.length > 0) ?
                    <CardsSkeleton/>:
                    <div  className="row mb-4">
                    {
                        festivals.map((festival,i)=>(
                            <div key={i} className='col-md-6 mb-4'>
                                <FestivalCard festival={festival}/>
                            </div>    
                           ))
                        }
                    
                    </div>
                }
                
             
          
            </div>
        </section>
  )
}

const CardsSkeleton=()=>{
    return(
        <>
          <div className='row mb-4'>
            <div className='col-md-6 mb-4'>
                <div className='content-card'>
                    <div style={{marginTop:'-5px'}}>
                    <Skeleton height={300} />  
                    </div>
                    <div className='mx-4 mb-4 pb-4'>
                    <Skeleton width={200} className="mx-auto mt-3"/> 
                    <Skeleton width={250} className="mx-auto mt-2 mb-5"/>
                    <Skeleton count={5} /> 
                    </div>
                </div>
            </div>
            <div className='col-md-6 mb-4'>
                <div className='content-card'>
                    <div style={{marginTop:'-5px'}}>
                    <Skeleton height={300} />  
                    </div>
                    <div className='mx-4 mb-4 pb-4'>
                    <Skeleton width={200} className="mx-auto mt-3"/> 
                    <Skeleton width={250} className="mx-auto mt-2 mb-5"/>
                    <Skeleton count={5} /> 
                    </div>
                </div>
            </div>
            <div className='col-md-6 mb-4'>
                <div className='content-card'>
                    <div style={{marginTop:'-5px'}}>
                    <Skeleton height={300} />  
                    </div>
                    <div className='mx-4 mb-4 pb-4'>
                    <Skeleton width={200} className="mx-auto mt-3"/> 
                    <Skeleton width={250} className="mx-auto mt-2 mb-5"/>
                    <Skeleton count={5} /> 
                    </div>
                </div>
            </div>
            <div className='col-md-6 mb-4'>
                <div className='content-card'>
                    <div style={{marginTop:'-5px'}}>
                    <Skeleton height={300} />  
                    </div>
                    <div className='mx-4 mb-4 pb-4'>
                    <Skeleton width={200} className="mx-auto mt-3"/> 
                    <Skeleton width={250} className="mx-auto mt-2 mb-5"/>
                    <Skeleton count={5} /> 
                    </div>
                </div>
            </div>

          </div>
        </>
    )
}

export default FestivalsCardWrap