import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useLayoutEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import ApiService from '../../api/ApiService'

const Banner = () => {

  const bannerRef = useRef()
  const [banner, setBanner] = useState(null)

  const fetchBanner = async (location) => {
    try {
      let res = await ApiService.fetchBanner(location)
      setBanner(res)
    } catch (error) {
      console.log(error)
    }
  }
  // const handleScrolltoForAllPages = () => {

  //   setTimeout(() => {
  //     const scrollToDonateFromRef = document.getElementById('scrollToAllPages');


  //     if (scrollToDonateFromRef) {
  //       const offset = -600;
  //       const targetScrollTop = scrollToDonateFromRef.offsetTop - offset;

  //       const container = document.documentElement;

  //       container.scrollTop = targetScrollTop;
  //     } else {
  //       console.error("Element scrollToDonateFromRef not found!");
  //     }
  //   }, 1000);
  // };

  useLayoutEffect (() => {
    
    fetchBanner('homepage')
    gsap.registerPlugin(ScrollTrigger)

    ScrollTrigger.create({
      trigger: bannerRef.current,
      start: 'top top',
      pin: true,
      pinSpacing: false
    })

    return () => {
      ScrollTrigger.getAll().forEach(t => t.kill())
    };


  },[])

  return (
    <section style={{ backgroundImage: `url(${process.env.REACT_APP_S3_URL}/uploads/banners/${banner?.image})` }} ref={bannerRef} className="banner bannerScroll index-banner">
        <div  className="container position-relative">
            <div className="banner-content ">
                <span>DISSOLVE IN KRISHNA CONSCIOUSNESS</span>
                <Link className="custom-btn-cls border-box" to="/join-family" 
                // onClick={handleScrolltoForAllPages}
                >JOIN THE DEVOTIONAL FAMILY</Link>
            </div>
        </div>
    </section>
  )
}

export default Banner