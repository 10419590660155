import PageHeader from "../components/common/PageHeader"
import { useEffect, useState } from 'react';
import ApiService from '../api/ApiService';
import GoToTop from "../helpers/GoToTop"
import Fancybox from "../helpers/Fancybox"
import Skeleton from "react-loading-skeleton";
import MetaData from "../helpers/MetaData";
import { useLocation } from "react-router-dom";
import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";

const Movement = () => {

    const location = useLocation()

    const scrollToElement = () => {
        const element = document.getElementById('scrollToAllPages');
        // console.log("elements",element);
        if(element) {
          element.scrollIntoView({behavior : 'smooth'})
        }
      };

    const [vCout, setVCount] = useState(9)
    const [videos,setVideos]=useState([])
    const [showSkeleton,setShowSkeleton]=useState(true)
    const fetchVideos=async()=>{
        try{
             const res= await ApiService.fetchVideos()
             setVideos(res)
             setShowSkeleton(false)
            }catch(error){
                console.log(error)
            }
    }

    
 
    useEffect(() => {
        fetchVideos()
        if(location.state) {
            setVCount(location.state.vidCount)
        }
      }, [])

      useEffect(()=>{

        scrollToElement();
        },[])
      const [meta, setMeta] = useState(null)
      const fetchMeta = async (pagename) => {
          try {
              let res = await ApiService.fetchMetaData(pagename)
              setMeta(res)
          } catch (error) {
              console.log(error)
          }
      }
  
      useEffect(() => {
          fetchMeta('movement')
      }, [])

    

  return (
    <>
        <MetaData title="Hare Krishna Movement - ISKCON of Attapur" />
        <PageHeader title="HARE KRISHNA MOVEMENT" imgSrc="/images/page-header/10.webp" />
        <section className="yt-videos">
            <div className="container">
                <div className="row d-flex align-items-center justify-content-center"   id="scrollToAllPages">
                    <div className="col-lg-10">
                        <div className="heading  d-flex align-items-center  flex-column">
                            <h2 className="head-1">WHY KRISHNA? </h2>
                            <p className="b-line">Deep dive into the spiritual oasis that is the Hare Krishna Movement by way of these insightful short-films, which even as they sate your curiosity are sure to evoke the great thirst in you. The thirst for Krishna, the desire to serve Him and to walk the path of Bhakti, which is the religion of love and is the core of the Hare Krishna Movement.</p>
                        </div>
                    </div>
                </div>
                <div className="video-wrap">
                    <div className="row my-5">
                       {
                           showSkeleton?
                           <>
                            {
                                Array.from(Array(vCout), (e,i) => (
                                    <div className="col-lg-4 col-md-6 mb-5" key={i}>
                                        <Skeleton height={230}/>
                                    </div>
                                ))
                            }
                           </>:
                           <>
                              {
                                    videos.map((video,i)=>(
                                        <div key={i} className="col-lg-4 col-md-6">
                                            <div className="video-holder">
                                                <Fancybox options={{keyboard: {Escape: "close"}}}>    
                                                    <a data-fancybox="video" href={video?.video} data-thumb={`${process.env.REACT_APP_S3_URL}/uploads/whykrishna/${video?.thumbnailImage}`} >
                                                        <figure className="mb-0 thumbnil">
                                                            <img src={`${process.env.REACT_APP_S3_URL}/uploads/whykrishna/${video?.thumbnailImage}`} alt=""/>
                                                        </figure>
                                                        <div className="yt-logo">
                                                            <figure className="yt-figure">
                                                                <img src="/images/homepage/why-krishna/youtube.jpg" alt=""/>
                                                            </figure>
                                                        </div>
                                                        <div className="reason">
                                                            <p>{video?.title} </p>
                                                        </div>
                                                    </a>
                                                </Fancybox>
                                            </div>
                                        </div> 
                                    ))
                        }
                           </>
                       }
                   
                    </div>
                </div>
            </div>
        </section>
        <GoToTop />
    </>

  )
}

export default Movement